import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class GroupService {
  apiUrl: any = environment.api.group;

  constructor(
    private commonService: CommonService
  ) { }

  getGroups() {
    return this.commonService.get(this.apiUrl, localStorage.getItem('token'), {});
  }

  addGroup(obj) {
    return this.commonService.create(`${this.apiUrl}`, localStorage.getItem('token'), obj);
  }

  update(obj) {
    return this.commonService.edit(this.apiUrl, localStorage.getItem('token'), obj);
  }

  delete(id) {
    return this.commonService.delete(`${this.apiUrl}`, localStorage.getItem('token'), id);
  }

  // upload(obj) {
  //   return this.commonService.upload(this.imageUrl, obj);
  // }

  // changePassword(obj) {
  //   return this.commonService.edit(`${this.apiUrl}/password`, localStorage.getItem('token'), obj);
  // }

  // changeEmail(obj) {
  //   return this.commonService.edit(`${this.apiUrl}/email`, localStorage.getItem('token'), obj);
  // }

  // getOrganizations() {
  //   return this.commonService.get(this.apiOrgUrl, localStorage.getItem('token'), {});
  // }

  // getUserOrganization() {
  //   return this.commonService.get(`${this.apiUrl}/common/status`, localStorage.getItem('token'), {});
  // }

  // changeOrganization(obj) {
  //   return this.commonService.edit(`${this.apiOrgUrl}/switch`, localStorage.getItem('token'), obj);
  // }

  // makeIndividual() {
  //   const user = { id: localStorage.getItem('userID'), status: 3}
  //   return this.commonService.edit(`${this.apiOrgUrl}/user-status`, localStorage.getItem('token'), user);
  // }

  // logout() {
  //   return this.commonService.logout(`${this.apiUrl}/logout`, localStorage.getItem('token'));
  // }
}
