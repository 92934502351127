import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Http } from '@angular/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//import { StripeService, Elements, Element as StripeElement, ElementsOptions } from 'ngx-stripe';
import { StripeService } from 'ngx-stripe';
import { StripeElements, StripeCardElement, StripeElementsOptions } from '@stripe/stripe-js'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { PlanService } from './../../services/plan.service';
import { PromotionService } from './../../services/promotion.service';
import { colors, dateFormat } from './../../../i18n/constants';
import Messages from './../../../i18n/messages';
import { CommonService } from './../../services/common.service';


@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  providers: [PlanService]
})
export class PlanComponent implements OnInit {
  busy: Promise<any>;
  plans: any;
  userPlan: any;
  modalRef: any;
  token: String = '';
  discount: Number = 0;
  elements: StripeElements;
  card: StripeCardElement;
  addCard: StripeCardElement;
  loader: Boolean = true;
  noPlan: Boolean = false;
  details: Boolean = false;
  newCard: Boolean = false;
  planForm: Boolean = false;
  payButton: Boolean = false;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;
  confButton: Boolean = false;
  confirmation: Boolean = false;
  selectedPlanName: String = '';
  selectedPlanAmount: String = '';
  selectedPlanPrice: String = '';
  paycancelButton: Boolean = false;
  confCancelButton: Boolean = false;
  wrongCoupon: Boolean = false;
  selectedAmount: any;
  @ViewChild('content') content: ElementRef;

  // optional parameters
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  stripeTest: FormGroup;
  newCardForm: FormGroup;

  constructor(
    private http: Http,
    private fb: FormBuilder,
    private _service: PlanService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private stripeService: StripeService,
    private commonService: CommonService,
    private promotionService: PromotionService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.newCardDeclation();
    this.token = localStorage.getItem('token');
    this.userPlan = { planName: '' };
    this.busy = this._service.planList().then(
      (res: any) => {
        if (res.data) {
          const plans = (res.data).map(function (element, index) {
            return { ...element, color: colors[index] };
          });
          this.plans = plans;
          this.getPlan(); // To get user's subscribed plan
        }
      }, (error) => {
        if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
          console.log(error.json().message);
        } else {
          console.log(Messages.somethingWrong);
        }
      }
    );

    const userEmail = localStorage.getItem('email');
    const userName = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`;
    this.stripeTest = this.fb.group({
      name: [userName, [Validators.required]],
      email: [userEmail, [Validators.required]],
      coupon: ['']
    });
    this.stripeService
      .elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#495057',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Poppins", sans-serif',
                fontSize: '13px',
                '::placeholder': {
                  color: '#cdd0d2',
                  fontSize: '14px',
                }
              }
            }
          });
          this.card.mount('#card-element');
        }
      });
  }

  getPlan() {
    this.busy = this._service.userPlan(this.token).then(
      (res: any) => {
        if (res.data && res.data.plan.planId) {
          const obj = (this.plans).find(o => o.id === res.data.plan.planId);
          let expireOn = '';
          let dayRemain = 0;
          if (res.data.current_period_end) {
            expireOn = moment(res.data.current_period_end * 1000).format('YYYY-MM-DD');
            const given = moment(expireOn, dateFormat.YYYYMMDD);
            const current = moment().startOf('day');
            dayRemain = moment.duration(given.diff(current)).asDays(); // Difference in number of days
          }

          this.userPlan = {
            planId: res.data.plan.planId,
            subscriptionId: res.data.plan.subscriptionId,
            planName: obj.name,
            expireOn: expireOn,
            dayRemain: dayRemain,
            cardBrand: res.data.card.brand,
            cardNumber: res.data.card.last4
          };

          this.showHide();
        } else {
          this.showHide();
        }
      },
      (error) => {
        if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
          console.log(this.token, error.json().message);
        } else {
          console.log(Messages.somethingWrong);
        }
      }
    );
    this.loader = false;
  }

  calculatePrice(price: any, discount: any) {
    const value = price[0] - (price[0] * discount / 100);
    return value;
  }

  onBlurMethod() {
    const coupon = this.stripeTest.get('coupon').value;
    if (coupon.trim().length) {
      this.promotionService.getPromotionsByCode(coupon).then(
        (res: any) => {
          if (res.data && res.data.disPercentage) {
            this.discount = res.data.disPercentage;
            const price = this.selectedPlanAmount.split(' ');
            this.selectedAmount = this.calculatePrice(price, this.discount);
            this.selectedPlanAmount = `${this.selectedAmount} ${price[1]}`;
            this.wrongCoupon = false;
          } else {
            this.discount = 0;
            this.selectedPlanAmount = this.selectedPlanPrice;
            this.wrongCoupon = true;
          }
        }, () => {
          this.discount = 0;
          this.selectedPlanAmount = this.selectedPlanPrice;
          this.wrongCoupon = true;
        });
    } else {
      this.discount = 0;
      this.selectedPlanAmount = this.selectedPlanPrice;
      this.wrongCoupon = false;
    }
  }

  buy() {
    this.payButton = true;
    this.paycancelButton = true;
    const name = this.stripeTest.get('name').value;
    const token = localStorage.getItem('token');
    const coupon = this.stripeTest.get('coupon').value;

    this.stripeService
      .createToken(this.card, { name })
      .subscribe(obj => {
        if (obj) {
          if (obj.token && obj.token.id) {
            const cardDetails = {
              source: obj.token.id,
              plan: this.selectedPlanName,
              coupon: coupon,
              card: {
                last4: obj.token.card.last4,
                brand: obj.token.card.brand,
                exp_month: String(obj.token.card.exp_month),
                exp_year: String(obj.token.card.exp_year)
              }
            };

            this.busy = this._service.subscribePlan(cardDetails).then(
              (res: any) => {
                this.toastr.success(res.message);
                this.payButton = false;
                this.paycancelButton = false;
                this.commonService.notifyOther({ option: 'isSubscription', value: true });
                this.getPlan();
              },
              (error) => {
                if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
                  this.toastr.error(error.json().message);
                } else {
                  this.toastr.error(Messages.somethingWrong);
                }
                this.payButton = false;
                this.paycancelButton = false;
              }
            );
          } else {
            this.toastr.error(Messages.validCard);
            this.payButton = false;
            this.paycancelButton = false;
          }
        } else {
          // Error creating the token
          this.toastr.error(obj.error.message);
          this.payButton = false;
          this.paycancelButton = false;
        }
      });
  }

  showHide() {
    if (this.userPlan && this.userPlan.planId) {
      this.noPlan = false;
      this.planForm = false;
      this.payButton = false;
      this.confButton = false;
      this.confirmation = false;
      this.paycancelButton = false;
      this.confCancelButton = false;
      this.newCard = false;
      this.details = true;
    } else {
      this.details = false;
      this.planForm = false;
      this.payButton = false;
      this.confButton = false;
      this.confirmation = false;
      this.paycancelButton = false;
      this.confCancelButton = false;
      this.newCard = false;
      this.noPlan = true;
    }
  }

  subscribe(planId) {
    if (this.userPlan && this.userPlan.planId) {
      this.selectedPlanName = planId;
      this.noPlan = false;
      this.newCard = false;
      this.details = false;
      this.confirmation = true;
    } else {
      const obj = (this.plans).find(o => o.id === planId);
      this.selectedPlanName = planId;
      this.selectedPlanAmount = obj.amount + ' ' + obj.currency;
      this.selectedPlanPrice = obj.amount + ' ' + obj.currency;
      this.noPlan = false;
      this.details = false;
      this.newCard = false;
      this.planForm = true;
    }
  }

  updatePlan() {
    this.confButton = true;
    this.confCancelButton = true;
    const details = {
      planId: this.selectedPlanName,
      subscriptionId: this.userPlan.subscriptionId
    };
    this.busy = this._service.updatePlan(details).then(
      (res: any) => {
        this.toastr.success(res.message);
        this.getPlan();
      }, (error) => {
        if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
          this.toastr.error(error.json().message);
        } else {
          this.toastr.error(Messages.somethingWrong);
        }
        this.confButton = false;
        this.confCancelButton = false;
      }
    );
  }

  open(content) {
    this.submitBtn = false;
    this.cancelBtn = false;
    this.modalRef = this.modalService.open(content);
  }

  cancelSubscription() {
    this.submitBtn = true;
    this.cancelBtn = true;
    this._service.unsubscribePlan().then((res: any) => {
      this.userPlan = '';
      this.toastr.success(res.message);
      this.modalRef.close();
      this.commonService.notifyOther({ option: 'isSubscription', value: false });
      this.getPlan();
      // localStorage.setItem('isSubscription', 'false');
    }, (error) => {
      this.modalRef.close();
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  newCardDeclation() {
    this.newCardForm = this.fb.group({
      name: ['', [Validators.required]],
    });
    this.elementsOptions = {
      locale: 'en'
    };

    this.stripeService
      .elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.addCard) {
          this.addCard = this.elements.create('card', {
            style: {
              base: {
                iconColor: '#666EE8',
                color: '#495057',
                lineHeight: '40px',
                fontWeight: 300,
                fontFamily: '"Poppins", sans-serif',
                fontSize: '13px',
                '::placeholder': {
                  color: '#cdd0d2',
                  fontSize: '14px',
                }
              }
            }
          });
          this.addCard.mount('#new-card-element');
        }
      });
  }

  addNewCard() {
    this.noPlan = false;
    this.details = false;
    this.newCard = true;
    this.newCardDeclation();
  }

  saveNewCard() {
    this.payButton = true;
    this.paycancelButton = true;
    const name = this.newCardForm.get('name').value;
    const token = localStorage.getItem('token');

    this.stripeService
      .createToken(this.addCard, { name })
      .subscribe(obj => {
        if (obj) {
          if (obj.token && obj.token.id) {
            const cardDetails = {
              source: obj.token.id
            };

            this._service.addNewCard(cardDetails).then(
              (res: any) => {
                this.toastr.success(res.message);
                this.payButton = false;
                this.paycancelButton = false;
                this.newCard = false;
                this.noPlan = true;
                this.commonService.notifyOther({ option: 'isSubscription', value: true });
              },
              (error) => {
                if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
                  this.toastr.error(error.json().message);
                } else {
                  this.toastr.error(Messages.somethingWrong);
                }
                this.payButton = false;
                this.paycancelButton = false;
              }
            );
          } else {
            this.toastr.error(Messages.validCard);
            this.payButton = false;
            this.paycancelButton = false;
          }
        } else {
          // Error creating the token
          this.toastr.error(obj.error.message);
          this.payButton = false;
          this.paycancelButton = false;
        }
      });
  }
}
