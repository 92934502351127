import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxLinkedinModule } from 'ngx-linkedin';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angular2SocialLoginModule } from 'angular2-social-login';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { HomeModule } from './home/home.module';
import { AppComponent } from './app.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ShareButtonModule } from '@ngx-share/button';
import { NgxVcardModule } from 'ngx-vcard';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { ChartsModule } from 'ng2-charts';
import { FilterPipe } from './shared/filter.pipe';
import { UiSwitchModule } from 'ngx-toggle-switch';

/** Services */
import { AuthGuard } from './services/auth/auth.guard';
import { AuthGuardService } from './services/auth/auth.service';
import { UsersService } from './services/user.service';
import { CommonService } from './services/common.service';
import { LoginService } from './services/auth/login.service';
import { SignupService } from './services/auth/signup.service';
import { AccountVerificationService } from './services/auth/account-verification.service';
import { AdDirective } from './shared/ad/ad.directive';
import { AdService } from './shared/ad/ad.service';

import { CardsService } from './services/card.service';
import { GroupService } from './services/group.service';
import { PromotionService } from './services/promotion.service';
import { DashboardService } from './services/dashboard.service';
import { TransactionService } from './services/transaction.service';
import { BroadcastService } from './services/broadcast.service';
import { AudioRecordingService } from './services/audio-recording.service';

/** Auth components */
import { LoginComponent } from './auth/login/login.component';
import { SignupComponent } from './auth/signup/signup.component';
import { AccountVerificationComponent } from './auth/account-verification/account-verification.component';
import { ForgetPasswordComponent } from './auth/forget-password/forget-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
// import { LayoutComponent } from './layout/layout.component';

/** Components */
import { CreateCardComponent } from './components/card/create-card/create-card.component';
import { AddCardComponent } from './components/card/add-edit-card/add-edit-card.component';
import { ListCardComponent } from './components/card/list-card/list-card.component';
import { ViewCardComponent } from './components/card/view-card/view-card.component';
import { ModalComponent } from './shared/utils/modal/modal.component';
import { SharedCardComponent } from './components/card/shared-card/shared-card.component';
import { TableComponent } from './components/common/table/table.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EventsComponent } from './components/events/events.component';
import { StaticPagesComponent } from './components/static-pages/static-pages.component';
import { AdBannerComponent } from './shared/ad/ad-banner.component';
import { HeroJobAdComponent } from './shared/ad/hero-job-ad.component';
import { HeroProfileComponent } from './shared/ad/hero-profile.component';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PlanComponent } from './components/plan/plan.component';
import { UserComponent } from './components/user/user-list/user.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangeOrganizationComponent } from './components/change-organization/change-organization.component';
import { InviteComponent } from './components/user/user-invite/invite.component';
import { GroupComponent } from './components/group/group.component';
import { PromotionComponent } from './components/promotion/promotion.component';
import { TransactionComponent } from './components/transaction/transaction.component';
import { BroadcastComponent } from './components/broadcast/broadcast.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

const socialloginproviders = {
  'linkedin': {
    'clientId': '81izknkma0l4z0'
  },
  'facebook': {
    'clientId': '1528467607290167',
    'apiVersion': 'v2.11'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    AccountVerificationComponent,
    ForgetPasswordComponent,
    ResetPasswordComponent,
    CreateCardComponent,
    AddCardComponent,
    ListCardComponent,
    ViewCardComponent,
    ModalComponent,
    SharedCardComponent,
    ProfileComponent,
    EventsComponent,
    StaticPagesComponent,

    DashboardComponent,
    PlanComponent,
    UserComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    ChangeOrganizationComponent,
    InviteComponent,
    GroupComponent,
    PromotionComponent,
    TransactionComponent,
    BroadcastComponent,
    FilterPipe,
    TableComponent,
    ContactUsComponent
  ],
  imports: [
    UiSwitchModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    Angular2SocialLoginModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      maxOpened: 1
    }),
    NgxStripeModule.forRoot('pk_live_mUUFCnvKZLaOxadElMRG7n7b00eeGhQpmn'),
    NgxSpinnerModule,
    HomeModule,
    GooglePlaceModule,
    NgxLinkedinModule.forRoot({
      clientId: '81izknkma0l4z0'
    }),
    ImageCropperModule,
    ShareButtonModule.withConfig({
      debug: true
    }),
    NgxVcardModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ChartsModule
  ],
  providers: [
    AuthGuardService,
    AuthGuard,
    CommonService,
    UsersService,
    LoginService,
    SignupService,
    AccountVerificationService,
    AuthGuardService,
    DashboardService,
    CardsService,
    GroupService,
    PromotionService,
    AudioRecordingService
  ],
  entryComponents: [
    ModalComponent,
    HeroJobAdComponent,
    HeroProfileComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

Angular2SocialLoginModule.loadProvidersScripts(socialloginproviders);

// https://www.carbonatethis.com/sort-table-columns-with-angular-and-typescript/
