import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

import { BaseService } from './../../services/base-service';
import { environment } from './../../../environments/environment';

export class User {
  constructor(
    public email: string,
    public password: string,
    public role: string
  ) { }
}

@Injectable()
export class AccountVerificationService {
  apiUrl: string = environment.api.root;

  constructor(
    private _router: Router,
    private http: Http,
  ) { }

  accountVerification(token) {
    return this.http.get(this.apiUrl + 'user/' + token)
                    .toPromise().then(response => response.json());
  }
}
