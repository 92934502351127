import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class UsersService {
  apiUrl: any = environment.api.organization;

  constructor(
    private commonService: CommonService
  ) { }

  getUsers(userType) {
    return this.commonService.get(`${this.apiUrl}/user?status=${userType}`, localStorage.getItem('token'), {});
  }

  addUser(user) {
    return this.commonService.create(`${this.apiUrl}/user`, localStorage.getItem('token'), user);
  }

  updateStatus(user) {
    return this.commonService.edit(`${this.apiUrl}/user-status`, localStorage.getItem('token'), user);
  }

  sendInvitation(obj) {
    return this.commonService.create(`${this.apiUrl}/user/invite`, localStorage.getItem('token'), obj);
  }

  importXml(obj) {
    return this.commonService.importXml(`${this.apiUrl}/user/import`, localStorage.getItem('token'), obj);
  }
}
