import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FilterPipe } from './../../../shared/filter.pipe';
import { UsersService } from '../../../services/user.service';
import { CommonService } from '../../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { userStatus } from './../../../../i18n/constants';
import Messages from './../../../../i18n/messages';
import * as moment from 'moment';
import 'rxjs';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  busy: Promise<any>;
  country: String[];
  modalRef: any;
  data: any;
  users: any;
  page = 1;
  totalItems = 0;
  itemsPerPage = 10;
  loader: Boolean = true;
  heading: String = '';
  defaultSort: String = 'firstName';
  status: Object = userStatus;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;
  showSort: Boolean = true;
  sortRequestField: String = '';
  sortRequestOrder: String = 'asc';
  userData: Object;
  sortableData: any;
  searchableData: any;
  noData: Boolean = false;
  searchableList = ['firstName', 'lastName', 'email'];
  public model = {
    fname: '',
    lname: '',
    email: '',
    code: '+1',
    phone: '',
    password: '',
    plan:'0',
    role: 'user',
    requestStatus: 5,  // Default (All User) data listing
    search: ''
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _service: UsersService,
    private httpService: HttpClient,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    // Country dial_code
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.country = this.removeDuplicates(this.country);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );

    this.getUsers();
  }

  getUsers() {
    const action = Number(this.model.requestStatus) - 1;
    this.heading = userStatus[action].name;
    this.busy = this._service.getUsers(action).then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        const userList = res.data.map(row => {
          return ({
            ...row,
            lastLogin: moment(row.lastLogin).format('YYYY-MM-DD hh:mm:ss A'),
            status: row.isActive,
            statusText: this.status[row.isActive].shortName
          });
        });
        this.data = userList;
      } else {
        this.data = [];
      }
      this.searchableData = [];
      this.sortableData = [];
      this.loadPage(this.page);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  loadPage(page) {
    this.users = [];
    const start = (page - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    if (typeof this.searchableData !== 'undefined' && this.searchableData.length > 0) {
      this.totalItems = (this.searchableData).length;
      this.users = this.searchableData.slice(start, end);
    } else {
      this.totalItems = (this.data).length;
      this.users = this.data.slice(start, end);
    }
    this.loader = false;
    this.sortData('firstName');
  }

  sortData(field, type = 'string') {
    const sortElement = document.getElementById(this.sortRequestField + '-sort');
    const upElement = document.getElementById(this.sortRequestField + '-up');
    const downElement = document.getElementById(this.sortRequestField + '-down');
    if (this.searchableData.length > 0) {
      this.sortableData = this.searchableData;
    } else {
      this.sortableData = this.data;
    }

    if (this.sortRequestField === field) {
      if (this.sortRequestOrder === 'asc') {
        this.sortRequestOrder = 'desc';
      } else {
        this.sortRequestOrder = 'asc';
      }
    } else {
      if (this.sortRequestField) {
        if (sortElement || upElement || downElement) {
          sortElement.style.display = 'block';
          upElement.style.display = 'none';
          downElement.style.display = 'none';
        }
      }
      this.sortRequestField = field;
      this.sortRequestOrder = 'asc';
    }

    if (type === 'number') {
      // console.log("NUMBER-NUMBER-NUMBER-NUMBER-NUMBER");
    } else {
      if (sortElement) {
        sortElement.style.display = 'none';
      }
      if (this.sortRequestOrder === 'asc') {
        if (sortElement || downElement) {
          sortElement.style.display = 'block';
          downElement.style.display = 'none';
        }
        this.users = this.sortableData && (this.sortableData).sort(function (a, b) {
          const nameA = a[field] ? a[field].toLowerCase() : '';
          const nameB = b[field] ? b[field].toLowerCase() : '';
          return nameA.localeCompare(nameB);
        });
      } else {
        if (upElement || downElement) {
          upElement.style.display = 'none';
          downElement.style.display = 'block';
        }
        this.users = this.sortableData && (this.sortableData).sort(function (a, b) {
          const nameA = a[field] ? a[field].toLowerCase() : '';
          const nameB = b[field] ? b[field].toLowerCase() : '';
          return nameB.localeCompare(nameA);
        });
      }
    }
  }

  addUser() {
    this.submitBtn = true;
    this.cancelBtn = true;

    const user = {
      firstName: this.model.fname,
      lastName: this.model.lname,
      email: this.model.email,
      password: this.model.password,
      plan: this.model.plan,
      role: 'user',
      phoneNumber: {
        code: this.model.code,
        number: Number(this.model.phone)
      }
    };

    this.busy = this._service.addUser(user).then((res) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      this.getUsers();
      this.modalRef.close();
      this.model.fname = '';
      this.model.lname = '';
      this.model.email = '';
      this.model.password = '';
      return this.toastr.success(res.message);
    }, (error) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      const errJson = JSON.parse(error._body);
      this.modalRef.close();
      return this.toastr.error(errJson.message);
    });
  }

  searchUser() {
    this.searchableData = [];
    const filterPipe = new FilterPipe();
    const filteredArr = filterPipe.transform(this.data, this.model.search, this.searchableList);
    if (filteredArr && filteredArr.length) {
      this.searchableData = filteredArr;
    } else {
      this.noData = true;
    }
    this.loadPage(1);
  }

  searchReset() {
    this.searchableData = [];
    this.sortableData = [];
    this.model.search = '';
    this.noData = false;
    this.loadPage(1);
  }

  statusAction(id, status) {
    const user = { id: String(id), status: status };
    // console.log("User : ", user);
    this.busy = this._service.updateStatus(user).then((res: any) => {
      this.toastr.success('Updated successfully.');
      this.getUsers();
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  addPopup(content) {
    this.modalRef = this.modalService.open(content);
    // modalRef.componentInstance.name = 'World';
  }

  detailsPopup(content, userId) {
    // console.log("this.data", this.data, userId);
    this.userData = this.data.find(user => user._id === userId);
    // console.log("userData : ", this.userData);
    this.modalRef = this.modalService.open(content);
  }

  download() {
    const currentTimestamp = new Date().getTime();
    this.commonService.exportCsv(this.data, `UserExport-${currentTimestamp}`);
  }

  uploadXml(event) {
    const file = event.target.files[0];
    if (file) {
      this._service.importXml(file).then(
        (res: any) => {
          // console.log("RES : ",res);
          if (res.statusCode === 200) {
            this.getUsers();
            this.toastr.success(res.message);
          } else {
            this.toastr.error(Messages.somethingWrong);
          }
        }, (error) => {
          const errJson = JSON.parse(error._body);
          return this.toastr.error(errJson.message);
        });
    }
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  removeDuplicates(country) {
    return country.reduce((accum, row) => {
      if (accum.findIndex(r => r['dial_code'] === row['dial_code']) === -1) {
        accum.push(row);
      }
      return accum;
    }, []);
  }

  downloadSample() {
    const filename = 'import-users-format.xlsx';
    const url = `./assets/${filename}`;
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

}
