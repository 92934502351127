import { Component, OnInit, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from './../../services/dashboard.service';
import { AdService } from './../../shared/ad/ad.service';
import { AdItem } from './../../shared/ad/ad-item';
// import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Address } from './../../../../src/objects/address';
import { CommonService } from './../../services/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../../app.component.scss', './dashboard.component.scss'],
})

@Injectable()

export class DashboardComponent implements OnInit {
  // @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  busy: Promise<any>;
  advertisement: AdItem[];
  public reload = 0;
  public loader: Boolean = false;
  analytics: any;
  tableView: any = [];
  recentlyView: any = [];
  topFiveActiveOrg: any = [];
  topTenSharedUsers: any = [];
  sortBy: any = '';
  sortByMiles: any = '';
  graphData: any = {};
  lat: any = 0;
  long: any = 0;
  totalCards: Number = 0;
  public model = {
    address: ''
  };

  constructor(
    private adService: AdService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private _service: DashboardService,
  ) { }

  /* Bar Chart */
  public barChartOptions = {
    scaleShowVerticalLines: true,
    responsive: true,
    // legend: {position: 'bottom'}
  };
  public barChartType = 'bar';
  public lineChartType = 'line';
  public barChartLegend = true;

  public barChartViewData = [];
  public barChartViewLabels = [];
  public barChartShareData = [];
  public barChartShareLabels = [];
  public barChartThirdPartyShareData = [];
  public barChartThirdPartyShareLabels = [];
  public lineChartAudioData = [];
  public lineChartAudioLabels = [];


  /* Pie Chart */
  public pieChartLabels = [];
  public pieChartData = [];
  public pieChartType = 'pie';

  ngOnInit() {
    this.loader = true;
    console.log('here in dashboard')
    // this.advertisement = this.adService.getAds();
    this.getCommonStatus();
    this.getInitialLocationsData();
  }
  getInitialLocationsData() {
    /* Get user's current location */
    window.navigator.geolocation.getCurrentPosition(
      position => {
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        // console.log("INIT :", this.lat, this.long);
        this.getAnalyticsData('', this.lat, this.long, '');
        this.getCommonStatus();
      }, error => {
        switch (error.code) {
          case 1:
            console.log('Permission Denied');
            break;
          case 2:
            console.log('Position Unavailable');
            break;
          case 3:
            console.log('Timeout');
            break;
        }
        this.getAnalyticsData('year', 0, 0, '');

      }
    );
  }

  /************ Get Analytics data ***********/
  getAnalyticsData(type = 'year', lat = 0, long = 0, miles) {
    const milesValue = miles ? Number(miles) : '';
    this.loader = true;
    this.busy = this._service.getAnalytics(type, lat, long, milesValue).then((res: any) => {
      this.analytics = res.data;
      this.tableView = this.analytics.tableView;
      this.recentlyView = this.analytics.recentlyView;
      this.topFiveActiveOrg = this.analytics.topFiveActiveOrg;
      this.topTenSharedUsers = this.analytics.topTenSharedUsers;
      this.totalCards = this.analytics.locationWiseDataCount;
      /************* View Analytics ***********/
      this.barChartViewData = [];
      this.barChartViewLabels = this.analytics.view.reduce((arr, d) => {
        arr.push(Object.keys(d)[0]);
        return arr;
      }, []);
      const views = this.analytics.view.reduce((arr, d) => {
        arr.push(Object.values(d)[0]);
        return arr;
      }, []);
      this.barChartViewData = [{ data: views, label: 'Card Views Analytics' }];
      /************* Share Analytics ***********/
      this.barChartShareData = [];
      this.barChartShareLabels = this.analytics.share.reduce((arr, d) => {
        arr.push(Object.keys(d)[0]);
        return arr;
      }, []);
      const share = this.analytics.share.reduce((arr, d) => {
        arr.push(Object.values(d)[0]);
        return arr;
      }, []);
      this.barChartShareData = [{ data: share, label: 'Card Share Analytics' }];
      /************* Third Party Share Analytics ***********/
      this.barChartThirdPartyShareData = [];
      this.barChartThirdPartyShareLabels = this.analytics.thirdPartyShare.reduce((arr, d) => {
        arr.push(Object.keys(d)[0]);
        return arr;
      }, []);
      const ThirdPartyShare = this.analytics.thirdPartyShare.reduce((arr, d) => {
        arr.push(Object.values(d)[0]);
        return arr;
      }, []);
      this.barChartThirdPartyShareData = [{ data: ThirdPartyShare, label: 'Card Third-Party Shares Analytics' }];
      /************* Audio Analytics ***********/
      this.lineChartAudioData = [];
      this.lineChartAudioLabels = this.analytics.audio.reduce((arr, d) => {
        arr.push(Object.keys(d)[0]);
        return arr;
      }, []);
      const audio = this.analytics.audio.reduce((arr, d) => {
        arr.push(Object.values(d)[0]);
        return arr;
      }, []);
      this.lineChartAudioData = [{ data: audio, label: 'Card Audio Analytics' }];
      /************* Card Locations ***********/
      this.pieChartData = [];
      this.pieChartLabels = this.analytics.locationWiseData.reduce((arr, d) => {
        arr.push(d.address);
        return arr;
      }, []);
      this.pieChartData = this.analytics.locationWiseData.reduce((arr, d) => {
        arr.push(d.count);
        return arr;
      }, []);
      this.loader = false;
    }, (error) => {
      return this.toastr.error(error.message);
    });
  }

  getCommonStatus() {
    this._service.getCommonStatus().then(res => {
      if (res && res.data) {
        localStorage.setItem('isSubscription', res.data['isSubscription']);
        localStorage.setItem('newNotificationCount', res.data['newNotificationCount']);
        localStorage.setItem('thirdPartyNotifications', res.data['settings']['thirdPartyNotifications']);
        this.commonService.notifyOther({
          option: 'isSubscription',
          value: res.data['isSubscription'],
          notify: true,
          count: res.data['newNotificationCount']
        });
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }
  /********* Get location wise data with long and lat ************/
  handleAddressChange(address: Address) {
    this.lat = 0;
    this.long = 0;
    this.model.address = '';
    if (address.formatted_address) {
      this.model.address = address.formatted_address;
      if (address.geometry.location.lat()) {
        this.lat = address.geometry.location.lat();
      }
      if (address.geometry.location.lng()) {
        this.long = address.geometry.location.lng();
      }

      if (this.lat && this.long) {
        this.getAnalyticsData('', this.lat, this.long, '');
      }
    }
  }
  /********* Get location wise data with miles ************/
  getAnalyticsMilesData(miles: String) {
    if (miles && this.lat && this.long) {
      this.getAnalyticsData('', this.lat, this.long, miles);
    }
  }
  /********* Reset location wise ***********/
  reset() {
    this.getInitialLocationsData();
    this.model.address = '';
    this.sortByMiles = '';
  }
}


