import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

import { CommonService } from './../../services/common.service';
import { BaseService } from './../../services/base-service';
import { environment } from './../../../environments/environment';
//import { NotificationsService } from '../shared/notifications.service';

export class User {
  constructor(
    public email: string,
    public password: string) { }
}

@Injectable()
export class SignupService {
  apiUrl: string = environment.api.root;
  apiOrgUrl: String = environment.api.organization;

  constructor(
    private _router: Router,
    private http: Http,
    private commonService: CommonService
  ) { }

  signup(user) {
    return this.http.post(`${this.apiUrl}user`, user)
      .toPromise().then((res: Response) => res.json());
  }

  signupSocial(user) {
    return this.http.post(`${this.apiUrl}user/social`, user)
      .toPromise().then((res: Response) => res.json());
  }

  getOrganizations() {
    return this.commonService.get(`${this.apiOrgUrl}`, '', {});
  }

}
