import { Component, OnInit } from '@angular/core';
import { Router, Route } from '@angular/router';
import { CommonService } from './../services/common.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  subscribedUser: Boolean = false;
  checkLoggedIn: Boolean = false;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private commonService: CommonService,
  ) { }

  ngOnInit() {

    if (localStorage.getItem('isSubscription') === 'true') {
      this.subscribedUser = true;
    } else {
      this.subscribedUser = false;
    }

    if (!!localStorage.getItem('token')) {
      this.checkLoggedIn = true;
    } else {
      this.checkLoggedIn = false;
    }

    this.subscription = this.commonService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'isSubscription') {
        if (res.value) {
          this.subscribedUser = true;
        } else {
          this.subscribedUser = false;
        }
      }
    });
  }
}
