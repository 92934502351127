import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class PromotionService {
  apiUrl: any = environment.api.promotion;

  constructor(
    private commonService: CommonService
  ) { }

  getPromotions(status) {
    if (status === '2') {
      return this.commonService.get(`${this.apiUrl}/applied`, localStorage.getItem('token'), {});
    } else {
      const type = (status === '1') ? false : true;
      return this.commonService.get(`${this.apiUrl}/applied?status=${type}`, localStorage.getItem('token'), {});
    }
  }

  getPromotionsByCode(id) {
    return this.commonService.get(`${this.apiUrl}/${id}`, localStorage.getItem('token'), {});
  }
}

