import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class DashboardService {
  cardApiUrl: any = environment.api.card;
  apiUrl: String = environment.api.user;

  constructor(
    private commonService: CommonService
  ) { }

  getAnalytics(type, lat, long, miles) {
    // tslint:disable-next-line:max-line-length
    return this.commonService.get(`${this.cardApiUrl}/analytics-data?type=${type}&lat=${lat}&long=${long}&miles=${miles}`, localStorage.getItem('token'), {});
  }

  getCommonStatus() {
    return this.commonService.get(`${this.apiUrl}/common/status`, localStorage.getItem('token'), {});
  }
}
