import { Observable } from 'rxjs';
import { AuthGuardService } from './../../services/auth/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { NullAstVisitor } from '@angular/compiler';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  @Input()
  ngSwitch: any;
  busy: Promise<any>;
  notifications: any;
  public totalNotification: Number = 0;
  public notificationCount: Number = 0;
  public sidebarOpened = false;
  public name: String = '';
  public role: String = '';
  public userImage: String = '';
  public isLoggedIn: Boolean = false;
  public apiUrl = environment.api.user;
  public cardUrl = environment.api.card;
  public imageUrl = environment.api.fileUpload;
  public notificationUrl = environment.api.notification;

  constructor(
    private router: Router,
    config: NgbDropdownConfig,
    private authService: AuthGuardService,
    private location: Location,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.name = this.capitalize(localStorage.getItem('firstName')) + ' ' + this.capitalize(localStorage.getItem('lastName'));
    if (localStorage.getItem('profileImage')) {
      this.userImage = this.commonService.getFile(localStorage.getItem('profileImage'));
    }
    this.totalNotification = Number(localStorage.getItem('newNotificationCount'));
    this.commonService.notifyObservable$.subscribe((res) => {
      // console.log(' in hhome', res);
      if (res.hasOwnProperty('notify') && res.notify) {
        this.totalNotification = res.count;
      }
    });
  }

  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  /****** View notifications **********/
  viewNotifications() {
    this.getNotification();
  }
  /****** Get notifications **********/
  getNotification() {
    this.commonService.get(this.notificationUrl, localStorage.getItem('token'), {}).then((res: any) => {
      if (res.data && res.data.data.length > 0) {
        this.notificationCount = (res.data.data).length;
        this.totalNotification = res.data.newNotificationCount;
        localStorage.setItem('newNotificationCount', res.data['newNotificationCount']);
        this.notificationButton(res.data.data);
      }
    }, (error) => {
      const errJson = JSON.parse(error._body);
      // console.log("Navbar Notification Error :", errJson.message);
    });
  }

  notificationButton(data) {
    this.notifications = data.map(row => {
      const msgClass = 'noti-subject-bold';
      let acceptDeclineButton = false;
      if (row.category === 'share_access') {
        if (row.status === 0 || row.status === 1) {
          acceptDeclineButton = true;
        }
      }
      return ({
        msgclass: msgClass,
        message: row.message,
        targetId: row.targetId,
        targetChildId: row.targetChildId,
        isActionRequired: row.isActionRequired,
        acceptDeclineButton: acceptDeclineButton,
        createdAt: row.createdAt
      });
    });
  }

  notificationStatus(targetId, targetChildId, stats) {
    const object = {
      id: targetId,
      pid: targetChildId,
      status: stats
    };
    // console.log(object);
    this.commonService.edit(`${this.cardUrl}/share-request-approval`, localStorage.getItem('token'), object).then((res: any) => {
      // console.log("Done...", res);
      this.getNotification();
      this.toastr.success(res.message);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      // console.log("Navbar Notification Error :", errJson.message);
    });
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  logout() {
    this.commonService.logout(`${this.apiUrl}/logout`, localStorage.getItem('token')).then((resLogout) => {
      localStorage.removeItem('userID');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('profileImage');
      localStorage.removeItem('isSubscription');
      localStorage.removeItem('thirdPartyNotifications');
      this.authService.logout();
      this.commonService.notifyOther({ option: 'logged-out' });
      this.spinner.show();
      setTimeout(() => {
        this.router.navigate(['/']);
        this.spinner.hide();
      }, 3000);
    }, error => {
      console.log("Navbar Logout error....");
      //return this.toastr.error(Messages.updateEmailLogoutError);
    });
  }

  setThirdParty() {
    console.log('--------------here')
  }
}
