import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/auth/login.service';
import { AccountVerificationService } from '../../services/auth/account-verification.service';
import { CommonService } from '../../services/common.service';
import Messages from './../../../i18n/messages';
import { iPhoneDevices, androidDevices } from './../../../i18n/constants';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-account-verification',
  providers: [AccountVerificationService],
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css']
})
export class AccountVerificationComponent implements OnInit {
  user: any = {};
  token: String = '';
  error: String = '';
  busy: Promise<any>;
  deviceLink: Boolean = false;
  loader: Boolean = false;
  errorMessage: any = '';

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private _service: LoginService,
    private spinner: NgxSpinnerService,
    private accountVerificationService: AccountVerificationService
  ) { }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['token']) {
        this.token = params['token'];
      }
    });
    this.accountVerification();
  }

  accountVerification() {
    if (this.token) {
      if (iPhoneDevices.includes(navigator.platform)) {
        this.deviceLink = true;
        try {
          window.location.href = `${environment.device.iPhone}/${this.token}`;
        } catch (e) {
          // app downlink;
        }
      } else if (androidDevices.includes(navigator.platform)) {
        this.deviceLink = true;
        try {
          window.location.href = `intent://business/#Intent;scheme=businesscard;package=com.mobi.card;end`; // `${environment.device.iPhone}/${this.token}`;
        } catch (e) {
          // app downlink;
        }
      } else {
        this.accountVerificationAction();
      }
    } else {
      this.loader = false;
      this.errorMessage = Messages.commonMessage;
      return this.toastr.error(Messages.commonMessage);
    }
  }

  accountVerificationAction() {
    this.busy = this.accountVerificationService.accountVerification(this.token).then(
      (res: any) => {
        if (res.statusCode === 200) {
          this.loader = true;
          const encryptedString = this.commonService.setToken('encrypt', res.data.loginToken.toString());
          localStorage.setItem('userID', res.data.userId);
          localStorage.setItem('role', res.data.role);
          localStorage.setItem('token', encryptedString);
          localStorage.setItem('email', res.data.email);
          localStorage.setItem('firstName', res.data.firstName);
          localStorage.setItem('lastName', res.data.lastName);
          localStorage.setItem('profileImage', res.data.profileImage);
          this.showSpinner();
        } else {
          this.loader = false;
          this.router.navigate(['/login']);
          return this.toastr.error(res.message);
        }
      },
      (error) => {
        if (error.headers._headers.get('content-type')[0] === 'application/json; charset=utf-8') {
          this.toastr.error(error.json().message);
        } else {
          this.toastr.error(Messages.resetUnable);
        }
      });
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.router.navigateByUrl('/dashboard');
    }, 3000);
  }
}
