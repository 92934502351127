import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { EventService } from './../../services/event.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [EventService]
})

export class EventsComponent {
  @ViewChild('modalContent') modalContent: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  selectedData: any;
  public model = {
    title: '',
    start: '',
    end: ''
  };
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.model.title = event['titleOriginal'];
        this.model.start = moment(event['startOriginal']).format('YYYY-MM-DD HH:mm');
        if (event.end) {
          this.model.end = moment(event['endOriginal']).format('YYYY-MM-DD HH:mm');
        } else {
          this.model.end = '';
        }
        this.handleEvent('Edit', event);
      }
    }, {
      label: '<i class="fa fa-fw fa-trash"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.selectedData = event;
        this.handleEvent('Delete', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: Boolean = false;
  loader: Boolean = true;
  addPopup: Boolean = false;
  editPopup: Boolean = false;
  deletePopup: Boolean = false;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;

  constructor(
    private modal: NgbModal,
    private toastr: ToastrService,
    private _service: EventService
  ) { }

  ngOnInit() {
    this.getEvents();
  }

  getEvents() {
    this._service.getEvents().then((res: any) => {
      const eventList = res.data.map(row => {
        let title = '';
        let endDate: any = '';
        let endTime: any = '';
        const startTime = moment(row.start).format('hh:mm A');
        const startDate = startOfDay(new Date(moment(row.start).format('YYYY-MM-DD')));
        if (row.end && row.end > 0) {
          endTime = moment(row.end).format('hh:mm A');
          endDate = startOfDay(new Date(moment(row.end).format('YYYY-MM-DD')));
          title = `[${startTime} - ${endTime}] - ${this.capitalize(row.title)}`;
        } else {
          title = `${startTime} - N/A ${this.capitalize(row.title)}`;
        }
        
        return ({
          ...row,
          title: title,
          start: startDate,
          startTime: startTime,
          end: endDate,
          endTime: endTime,
          actions: this.actions,
          titleOriginal: row.title,
          startOriginal: row.start,
          endOriginal: row.end
        });
      });
      this.events = eventList;
      this.loader = false;
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        console.log(isSameDay(this.viewDate, date));
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.addPopup = false;
    this.editPopup = false;
    this.deletePopup = false;
    console.log("11111111111111111",event);
    
    if (action === 'Add' || action === 'Edit') {
      this.addPopup = true;
      if (action === 'Add') {
        this.model.title = '';
        this.model.start = '';
        this.model.end = '';
      } else {
        //event.title = event['titleOriginal'];
        //const output = moment(event.title, 'hh:mm A').format('HH mm');
        //console.log(output);
      }
    } else if (action === 'Delete') { this.deletePopup = true; }

    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'sm' });
  }

  addEvent(): void {
    this.submitBtn = true;
    this.cancelBtn = true;
    if (this.modalData && this.modalData.action === 'Edit') {
      this.editEvent();
    } else {
      const startDate = moment(this.model.start).format('YYYY-MM-DD HH:mm');
      const startDateUnix = moment(startDate).unix() * 1000;
      let endDateUnix = 0;
      if (this.model.end) {
        const endDate = moment(this.model.end).format('YYYY-MM-DD HH:mm');
        endDateUnix = moment(endDate).unix() * 1000;
      }
      const obj = {
        title: this.model.title,
        start: startDateUnix,
        end: endDateUnix
      };
      this._service.addEvent(obj).then((res) => {
        this.submitBtn = false;
        this.cancelBtn = false;
        this.modal.dismissAll();
        this.loader = true;
        this.getEvents();
        return this.toastr.success(res.message);
      }, (error) => {
        this.submitBtn = false;
        this.cancelBtn = false;
        const errJson = JSON.parse(error._body);
        this.modal.dismissAll();
        return this.toastr.error(errJson.message);
      });
    }
  }

  editEvent(): void {
    const startDate = moment(this.model.start).format('YYYY-MM-DD HH:mm');
    const startDateUnix = moment(startDate).unix() * 1000;
    let endDateUnix = 0;
    if (this.model.end) {
      const endDate = moment(this.model.end).format('YYYY-MM-DD HH:mm');
      endDateUnix = moment(endDate).unix() * 1000;
    }
    const obj = {
      id: this.modalData.event['_id'],
      title: this.model.title,
      start: startDateUnix,
      end: endDateUnix
    };
    this._service.editEvent(obj).then((res) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      this.modalData.action = '';
      this.modal.dismissAll();
      this.loader = true;
      this.getEvents();
      return this.toastr.success(res.message);
    }, (error) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      const errJson = JSON.parse(error._body);
      this.modal.dismissAll();
      return this.toastr.error(errJson.message);
    });
  }

  delete() {
    const event = this.selectedData;
    this._service.deleteEvent(event._id).then((res: any) => {
      this.events = this.events.filter(iEvent => iEvent !== event);
      this.modal.dismissAll();
      return this.toastr.success(res.message);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
}
