import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthGuardService } from './../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { ProfileService } from '../../services/profile.service';
import Messages from './../../../i18n/messages';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
  providers: [ProfileService]
})

export class ChangeEmailComponent implements OnInit {
  @ViewChild('f') formValues;
  busy: Promise<any>;
  notes: String = Messages.updateEmailNote;
  submitButton: Boolean = false;
  public model = {
    email: '',
    confemail: '',
    role: localStorage.getItem('role')
  };

  constructor(
    private authService: AuthGuardService,
    private _router: Router,
    private commonService: CommonService,
    private _service: ProfileService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() { }

  changeEmail() {
    this.submitButton = true;
    if (this.model.email === this.model.confemail) {
      this.busy = this._service.changeEmail({
        email: this.model.email, role: this.model.role
      }).then((res) => {
        this.busy = this._service.logout().then((resLogout) => {
          localStorage.removeItem('userID');
          localStorage.removeItem('role');
          localStorage.removeItem('token');
          localStorage.removeItem('email');
          localStorage.removeItem('firstName');
          localStorage.removeItem('lastName');
          localStorage.removeItem('profileImage');
          this.authService.logout();
          this.commonService.notifyOther({ option: 'logged-out' });
          this.spinner.show();
          setTimeout(() => {
            this.toastr.success(res.message);
            this._router.navigate(['/login']);
            this.spinner.hide();
          }, 3000);
        },
          error => {
            return this.toastr.error(Messages.updateEmailLogoutError);
          });
      },
        error => {
          this.submitButton = false;
          const errJson = JSON.parse(error._body);
          return this.toastr.error(errJson.message);
        });
    } else {
      this.submitButton = false;
      return this.toastr.error(Messages.confirmEmail);
    }
  }

}
