import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthGuardService } from './../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { ProfileService } from '../../services/profile.service';
import Messages from './../../../i18n/messages';

@Component({
  selector: 'app-change-organization',
  templateUrl: './change-organization.component.html',
  styleUrls: ['./change-organization.component.scss'],
  providers: [ProfileService]
})

export class ChangeOrganizationComponent implements OnInit {
  @ViewChild('f') formValues;
  busy: Promise<any>;
  notes: String = Messages.updateOrganizationNote;
  organizations: any = [];
  userOrganization: String = '';
  indButton: Boolean = true;
  submitButton: Boolean = false;
  public model = {
    organization: ''
  }

  constructor(
    private authService: AuthGuardService,
    private _router: Router,
    private commonService: CommonService,
    private _service: ProfileService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getOrganizations(); // To get all organizations
  }

  getOrganizations() {
    this.busy = this._service.getOrganizations().then((res: any) => {
      if (res.data.length > 0) {
        this.organizations = res.data;
        this.getUserOrganization(); // Get user's organizationID (if any)
      }
    },
    error => {
      this.submitButton = false;
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  getUserOrganization() {
    this.busy = this._service.getUserOrganization().then((res: any) => {
      if (res.data.organizationId) {
        const userOrganizationDetails = (this.organizations).find(i => i.userId === res.data.organizationId);
        this.userOrganization = this.capitalize(userOrganizationDetails.companyName);
        this.indButton = false;
      }
    },
    error => {
      this.submitButton = false;
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  changeOrganization() {
    this.indButton = true;
    this.submitButton = true;
    const organizationId = { id: this.model.organization };
    this.busy = this._service.changeOrganization(organizationId).then((res) => {
      this.toastr.success(res.message);
    },
    error => {
      this.submitButton = false;
      if (this.userOrganization) {
        this.indButton = false;
      }
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  makeIndividual() {
    this.indButton = true;
    this.submitButton = true;
    this.busy = this._service.makeIndividual().then((res) => {
      this.toastr.success(res.message);
    },
    error => {
      this.indButton = false;
      this.submitButton = false;
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

}
