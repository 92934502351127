import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Router } from '@angular/router';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class CommonService {
  apiUrl: String = environment.api.base;
  pageUrl = environment.api.pages;
  rootUrl = environment.api.root;

  decryptedString: string = null;
  public storage: any;
  private notify = new Subject<any>();
  token = localStorage.getItem('token');
  // options: any;

  /**
   * Observable string streams
  */
  notifyObservable$ = this.notify.asObservable();

  constructor(
    private _router: Router,
    private http: Http
  ) { }

  getById(url, token) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.get(url, options)
      .toPromise().then((res: Response) => res.json());
  }

  get(url, token, paramsValue) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers, params: paramsValue });
    return this.http.get(url, options)
      .toPromise().then((res: Response) => res.json());
  }

  create(url, token, data) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.post(url, data, options)
      .toPromise().then((res: Response) => res.json());
  }

  edit(url, token, data) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.put(url, data, options)
      .toPromise().then((res: Response) => res.json());
  }

  delete(url, token, id) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.delete(url + '/' + id, options)
      .toPromise().then((res: Response) => res.json());
  }

  unsubscribe(url, token) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.delete(url, options)
      .toPromise().then((res: Response) => res.json());
  }

  /** ENCRYPT & DECRYPT the login token of user logged in */
  setToken(reqType, _token) {
    const encPassword = 'encode@123';
    if (_token && reqType === 'encrypt') {
      return CryptoJS.AES.encrypt(_token.trim(), encPassword).toString();
    } else if (_token && reqType === 'decrypt') {
      return CryptoJS.AES.decrypt((localStorage.getItem('token')).trim(), encPassword).toString(CryptoJS.enc.Utf8);
    } else {
      console.log('Token getting problem');
    }
  }

  getColor() {
    return this.http.get(this.apiUrl + '/api/colors')
      .toPromise().then((res: Response) => res.json());
  }

  getFile(path) {
    if (path.includes('http', 'https')) {
      return path;
    } else {
      return `${this.apiUrl}/api/file/${path}`;
    }
  }

  notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  upload(url, data) {
    const formData = new FormData();
    formData.append('file', data);
    return this.http.post(url, formData)
      .toPromise().then((res: Response) => res.json());
  }

  importXml(url, token, data) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    const formData = new FormData();
    formData.append('file', data);

    return this.http.post(url, formData, options)
      .toPromise().then((res: Response) => res.json());
  }

  logout(url, token) {
    this.decryptedString = this.setToken('decrypt', token);
    const headers = new Headers({ 'authorization': `Bearer ${this.decryptedString}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.delete(url, options)
      .toPromise().then((res: Response) => res.json());
  }

  generateRandom(length = 32, alphanumeric = true) {
    let data = '',
      keys = '';
    if (alphanumeric) {
      keys = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    } else {
      keys = '0123456789';
    }
    for (let i = 0; i < length; i++) {
      data += keys.charAt(Math.floor(Math.random() * keys.length));
    }
    return data;
  }

  /*
  * Export CSV File
  * data - Required (JSON)
  * filename - Optional (String) default filename 'SampleExport.csv'
  */
  exportCsv(data, filename = 'SampleExport') {
    const csvData = this.ConvertToCSV(data);
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
  }

  /*
  * Convert JSON to CSV
  * data - Required (JSON)
  */
  ConvertToCSV(objArray) {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (const index in objArray[0]) {
      // Convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    // Append Label row with line break
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  getPage(pageSlug) {
    return this.http.get(`${this.pageUrl}/${pageSlug}`)
      .toPromise().then((res: Response) => res.json());
  }

/** Landing page services */
  contactUs(data) {
    return this.http.post(this.rootUrl + 'admin/contact-support', data, {})
      .toPromise().then((res: Response) => res.json());
  }

  sendSms(sms) {
    return this.http.post(this.rootUrl + 'admin/sms', sms, {})
      .toPromise().then((res: Response) => res.json());
  }

  getSubscriptionPlans() {
    return this.http.get(`${this.rootUrl}subscription/plans-list`)
    .toPromise().then((res: Response) => res.json());
  }
}
