import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from './../../shared/filter.pipe'
import { TransactionService } from './../../services/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { userStatus } from './../../../i18n/constants';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  providers: [TransactionService]
})
export class TransactionComponent implements OnInit {
  busy: Promise<any>;
  modalRef: any;
  search: String;
  deleteRef: any;
  data: any;
  users: any;
  page = 1;
  totalItems = 0;
  itemsPerPage = 10;
  loader: Boolean = true;
  showSort: Boolean = true;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;
  editableId: String = '';
  deleteId: String = '';
  sortRequestField: String = '';
  sortRequestOrder: String = '';
  sortableData: any;
  searchableData: any;
  searchableList = ['name'];
  defaultSort: String = 'name';
  column: Array<any> = [
    { id: 'planName', name: 'Plan Name', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { id: 'planAmount', name: 'Amount', sorting: false, type: 'amount', display: 'left' },
    { id: 'discount', name: 'Discount', sorting: false, type: 'percent', display: 'left' },
    { id: 'mode', name: 'Transaction Mode', sorting: false, type: 'string', display: 'left' },
    { id: 'transactionOn', name: 'Transaction On', sorting: true, type: 'fulldatetime', display: 'left', order: 'neutral' }
  ];
  actions: Array<any>;
  isEdit: Boolean = true;
  isDelete: Boolean = false;
  @ViewChild('content') content: ElementRef;
  @ViewChild('delete') delete: ElementRef;
  public model = {
    name: '',
    description: '',
    requestStatus: 5,  // Default (All Group) data listing
    search: ''
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _service: TransactionService,
  ) { }

  ngOnInit() {
    this.getTransactions(); // To get user's details
  }

  getTransactions(reload = false) {
    this._service.getTransactions().then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.data = res.data;
      } else {
        this.data = [];
      }
      console.log(this.data);
      this.totalItems = this.data.length;
      this.loader = false;
      this.sliceList(this.page, this.data);
    }, (error) => {
      let errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  searchRecord() {
    if (this.search && this.search.trim().length) {
      this.users = this.data.filter(x => x.planName.includes(this.search));
      this.totalItems = this.users.length;
      this.page = 1;
      this.sliceList(this.page, this.users);
    } else {
      this.totalItems = this.data.length;
      this.page = 1;
      this.sliceList(this.page, this.data);
    }
  }

  searchReset() {
    this.search = '';
    this.searchRecord();
  }

  sliceList(page, data) {
    let start = (page - 1) * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    this.users = data.slice(start, end);
  }

  sortData(field, order) {
    this.column = this.column.map(x => field === x['id'] ? { ...x, 'order': order } : x);
    console.log(field);
    console.log(this.column);

    if (order == 'asc') {
      this.users = this.users.sort((a, b) => a[field].localeCompare(b[field]))
    } else {
      this.users = this.users.sort((a, b) => b[field].localeCompare(a[field]))
    }
  }

  onPageChange(page) {
    if (this.search && this.search.length) {
      this.sliceList(page, this.users);
    } else {
      this.sliceList(page, this.data);
    }
  }

}
