import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/auth/login.service';
import Messages from './../../../i18n/messages';

@Component({
  selector: 'app-reset-password',
  providers: [LoginService],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  busy: Promise<any>;
  token: String = '';
  errorMessage: String = '';
  loader: Boolean = true;
  passwordReset: Boolean = false;
  public model = {
    password: '',
    confpassword: ''
  }

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    // subscribe to router event
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['token']) {
        this.token = params['token'];
        this.verifyToken();
      }
    });
  }

  verifyToken() {
    this.busy = this.loginService.tokenVerification(this.token).then(
      (res: any) => {
        this.loader = false;
        this.passwordReset = true;
      },
      (error) => {
        this.loader = false;
        if (error.headers._headers.get('content-type')[0] == "application/json; charset=utf-8") {
          this.errorMessage = error.json().message;
          //this.toastr.error(error.json().message);
        } else {
          this.errorMessage = Messages.resetUnable;
          //this.toastr.error(Messages.resetUnable);
        }
      }
    );
  }

  resetPassword() {
    if (this.model.password === this.model.confpassword) {
      let reqParams = { password: this.model.password };
      this.busy = this.loginService.resetPassword(this.token, reqParams).then(
        (res: any) => {
          this.toastr.success(res.message);
          this.router.navigate(["/login"]);
        },
        (error) => {
          if (error.headers._headers.get('content-type')[0] == "application/json; charset=utf-8") {
            this.toastr.error(error.json().message);
          } else {
            this.toastr.error(Messages.resetUnable);
          }
        }
      );
    } else {
      return this.toastr.error(Messages.confirmPassword);
    }
  }
}
