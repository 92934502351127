import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../../services/user.service';
import { CommonService } from './../../../services/common.service';
import * as moment from 'moment';
import 'rxjs/Rx';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  busy: Promise<any>;
  modalRef: any;
  search: String;
  data: any;
  users: any;
  page = 1;
  totalItems = 0;
  itemsPerPage = 10;
  userData: Object;
  actions: Array<any>;
  loader: Boolean = true;
  column: Array<any> = [
    { id: 'firstName', name: 'First Name', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { id: 'lastName', name: 'Last Name', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { id: 'email', name: 'Email', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { name: 'Action', sorting: false, type: 'string', display: 'center' }
  ];

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _service: UsersService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.getUsers();

    this.actions = [{
      parentClass: "btn-primary",
      action: (item) => this.openDetailModal(this.content, item['_id']),
      title: "Detail View",
      childClass: "mdi-view-sequential"
    }, {
      parentClass: "btn-success",
      action: (item) => this.sendInvitation(item['_id']),
      title: "Invite",
      childClass: "mdi-email-outline"
    }];
  }

  getUsers(reload = false) {
    this._service.getUsers(5).then((res: any) => {  // 5 => All User data listing
      if (res.data && (res.data.length) > 0) {
        this.data = res.data;
      } else {
        this.data = [];
      }
      this.totalItems = this.data.length;
      this.loader = false;
      this.sliceList(this.page, this.data);
    }, (error) => {
      let errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  searchUser() {
    if (this.search && this.search.trim().length) {
      const search = this.search.split(' ');
      if (search.length > 1) {
        this.users = this.data.filter(
          x => x.firstName.toLowerCase() === search[0].toLowerCase()
            && x.lastName.toLowerCase() === search[1].toLowerCase());
      } else {
        this.users = this.data.filter(
          x => x.firstName.toLowerCase().includes(
            this.search.toLowerCase()) || x.lastName.toLowerCase().includes(
              this.search.toLowerCase()) || x.email.toLowerCase().includes(
                this.search.toLowerCase()));
      }
      this.totalItems = this.users.length;
      this.page = 1;
      this.sliceList(this.page, this.users);
    } else {
      this.totalItems = this.data.length;
      this.page = 1;
      this.sliceList(this.page, this.data);
    }
  }

  searchReset() {
    this.search = '';
    this.searchUser();
  }

  sliceList(page, data) {
    const start = (page - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.users = data.slice(start, end);
  }

  sortData(field, order) {
    this.column = this.column.map(x => field === x['id'] ? { ...x, 'order': order } : x);
    if (order === 'asc') {
      this.users = this.users.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      this.users = this.users.sort((a, b) => b[field].localeCompare(a[field]));
    }
  }

  onPageChange(page) {
    if (this.search && this.search.length) {
      this.sliceList(page, this.users);
    } else {
      this.sliceList(page, this.data);
    }
  }

  sendInvitation(userId) {
    const selectedUser = { uid: userId };
    this._service.sendInvitation(selectedUser).then((res: any) => {
      const index = this.data.findIndex(row => row._id === userId);
      if (index > -1) {
        this.data.splice(index, 1);
      }
      this.sliceList(this.page, this.data);
      return this.toastr.success(res.message);
    }, (error) => {
      let errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  openDetailModal(content, userId) {
    this.userData = this.data.find(user => user._id === userId);
    if (this.userData['profileImage']) {
      this.userData['profileImage'] = this.commonService.getFile(this.userData['profileImage']);
    }
    this.modalRef = this.modalService.open(content);
  }

  getUtcTime(milliseconds) {
    return moment(milliseconds).format('YYYY-MM-DD hh:mm:ss A');
  }

}
