import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from '../../../services/common.service';
// import { EmailValidator } from '@ang../../../../../src/app/shared/ngx-google-places-autocomplete.directive

@Component({
  selector: 'app-create-card',
  templateUrl: './create-card.component.html'
})

export class CreateCardComponent implements OnInit {
  cardRequest = false;
  selectedItem: Number = null;
  domainUrl: any = '';
  nextBtn: Boolean = false;
  loader: Boolean = true;

  constructor(
    private commonService: CommonService,
    private router: Router
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.loader = false;
    }, 500);
  }

  open(cardNo: Number) {
    this.commonService.storage = { cardNo: cardNo };
    this.cardRequest = true;
    this.selectedItem = cardNo;
  }

  async checkDomain() {
    if (this.domainUrl) {
      this.nextBtn = true;
      const domainName = this.extractDomain(this.domainUrl);
      const domainData = await (await fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${domainName}`)).json();
      if (this.domainUrl) {
        localStorage.setItem('domainUrl', this.domainUrl);
        this.commonService.storage = Object.assign(
          {
            email: this.domainUrl,
            domainData: domainData
          }, this.commonService.storage);
        this.router.navigate(['/add-card']);
      }
    }
  }

  extractDomain(_url) {
    const email = _url;
    const email_string_array = email.split('@');
    const domain_string_location = email_string_array.length - 1;
    const final_domain = email_string_array[domain_string_location].split('.');
    return final_domain[0];
  }
}
