import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http } from '@angular/http';
import { CommonService } from './common.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import { environment } from '../../environments/environment';

export class User {
  constructor(
    public email: String,
    public password: String) { }
}

@Injectable()
export class PlanService {
  apiUrl: String = environment.api.subscription;
  promotionUrl: String = environment.api.promotion;

  constructor(
    private _router: Router,
    private commonService: CommonService,
    private http: Http,
  ) { }

  userPlan(token) {
    return this.commonService.get(this.apiUrl, localStorage.getItem('token'), {});
  }

  planList() {
    return this.commonService.get(this.apiUrl + '/plan', localStorage.getItem('token'), {});
  }

  subscribePlan(obj) {
    return this.commonService.create(this.apiUrl, localStorage.getItem('token'), obj);
  }

  unsubscribePlan() {
    return this.commonService.unsubscribe(this.apiUrl, localStorage.getItem('token'));
  }

  updatePlan(obj) {
    return this.commonService.edit(this.apiUrl, localStorage.getItem('token'), obj);
  }

  addNewCard(obj) {
    return this.commonService.create(this.apiUrl + '/add-new-card', localStorage.getItem('token'), obj);
  }
}
