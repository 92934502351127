import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-static-pages',
  templateUrl: './static-pages.component.html',
  styleUrls: ['./static-pages.component.scss']
})

export class StaticPagesComponent implements OnInit {
  paramsValue: any;
  page: any;

  faqList: Array<object> = [];
  faqExists: Boolean = false ;

  constructor(
    private toastr: ToastrService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.paramsValue = params['slug'];
      if (this.paramsValue) {
        if (this.paramsValue === 'faq') {
          this.faqExists = true;
        } else {
          this.faqExists = false;
        }
        this.commonService.getPage(this.paramsValue).then( res => {
          if (this.faqExists) {
          this.faqList = res['data']['faqContent'];
          } else {
            this.page = res.data['content'];
          }
        }, error => {
          this.toastr.error(error.message);
        });
      }
    });
  }

  getTitle() {
    if (this.paramsValue === 'about-us') {
      return 'About Us';
    } else if (this.paramsValue === 'privacy-policy') {
      return 'Privacy Policy';
    } else if (this.paramsValue === 'terms-conditions') {
      return 'Terms & Conditions';
    } else {
      return 'Frequently Asked Questions';
    }
  }
}
