import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class EventService {
  apiUrl: any = environment.api.event;

  constructor(
    private commonService: CommonService
  ) { }

  getEvents() {
    return this.commonService.get(`${this.apiUrl}`, localStorage.getItem('token'), {});
  }

  addEvent(obj) {
    return this.commonService.create(`${this.apiUrl}`, localStorage.getItem('token'), obj);
  }

  editEvent(obj) {
    return this.commonService.edit(`${this.apiUrl}`, localStorage.getItem('token'), obj);
  }

  deleteEvent(id) {
    return this.commonService.delete(`${this.apiUrl}`, localStorage.getItem('token'), id);
  }
}
