import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  styles: ['.fa{cursor:pointer; padding:0 3px;} .left{text-align:left;} .right{text-align:right;} .center{text-align:center;} .mystatus{padding:2px; margin:0 3px;}']
})
export class TableComponent implements OnInit {
  sorting: String = 'neutral';
  @Input('items') items: Array<any>;
  @Input('columns') columns: Array<any>;
  @Input('sortData') sortData: Function;
  @Input('editmodel') content: any;
  @Input('deletemodal') delete: any;
  @Input('actions') actions: any;
  constructor() { }

  ngOnInit() {
  }

}
