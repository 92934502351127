import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CardsService } from '../../../services/card.service';
import { CommonService } from '../../../services/common.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { iPhoneDevices, androidDevices } from '../../../../i18n/constants';
import { GroupService } from '../../../services/group.service';
import { VCard } from 'ngx-vcard';
import { AdItem } from '../../../shared/ad/ad-item';
import { AdService } from '../../../shared/ad/ad.service';
import { HeroProfileComponent } from '../../../shared/ad/hero-profile.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { timeStamp } from 'console';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-shared-card',
  templateUrl: './shared-card.component.html'
})
export class SharedCardComponent implements OnDestroy, OnInit {
  loader: Boolean = true;
  cardDetail: Object = {};
  isData: Boolean = false;
  isError: Boolean = false;
  shareUrl = environment.api.shareCardLink;
  cardUrl = environment.api.card;

  intPhoneValue: any;
  SHARE_LINK = '';

  cardList: Array<object> = [];
  _card: object = {};
  country: String[];
  publicShare: Boolean = false;
  privateForm: FormGroup;
  paramsValues: any;
  title: any = 'Verify';
  cardNote: any = '';
  cardVerification: Boolean = false;

  username: String = '';
  email: String = '';
  phone: String = '';
  pid: String = '';
  firstName: String = '';
  lastName: String = '';

  groupList: Array<object> = [];
  favourite: Boolean = false;
  connectCard: any;
  saveToGroup: any;
  selectedGroup: any = '';
  cardSlug = '';
  shareType: any;
  shareMode: any;
  thirdPartyShare: Boolean = false;
  smsNumber: String = '';

  process: Boolean = false;
  audioPlayer = new Audio();
  currentAudioTime: string = "00:00";
  audioLength: string = "00:00";
  isPlayingAudio: Boolean = false; 
  isAudioReady: Boolean = false;
  shouldCountAudioPlay: Boolean = true; 
  audioProgress: number = 0; 

  advertisement: AdItem[];
  subscribedUser: Boolean = false;
  private subscription: Subscription;

  @ViewChild('shareModal')
  private shareModal: TemplateRef<any>;

  public vCard: VCard = {};

  constructor(config: NgbModalConfig,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private httpService: HttpClient,
    private fb: FormBuilder,
    private cardsService: CardsService,
    private groupService: GroupService,
    private activatedRoute: ActivatedRoute,
    private adService: AdService,
    private cdr: ChangeDetectorRef
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.getCountryCode();
    this.checkSubscription();
    this.activatedRoute.params.subscribe(params => {
      this.paramsValues = params;
      this.createForm();
      if (params && !params.pid) {
        if (localStorage.getItem('verified-card') !== this.paramsValues.slug) {
          this.cardVerification = false;
          if (iPhoneDevices.includes(navigator.platform)) {
            window.location.href = `${environment.device.iPhone}/${params.type}/${params.slug}/${params.pid}`;
            setTimeout(() => {
              this.modalService.open(this.shareModal);
              this.loader = false;
            }, 3000);
          } else if (androidDevices.includes(navigator.platform)) {
            window.location.href = `${environment.device.android}/${params.type}/${params.slug}/${params.pid}`;
            setTimeout(() => {
              this.modalService.open(this.shareModal);
              this.loader = false;
            }, 3000);
          } else {
            setTimeout(() => {
              this.loader = false;
              this.modalService.open(this.shareModal);
            }, 500);
          }
        } else {
          this.cardVerification = true;
          this.checkDeviceLink(params);
        }
      } else {
          this.cardVerification = true;
          this.checkDeviceLink(params);
      }
    });
    this.subscription = this.commonService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'isSubscription') {
        localStorage.setItem('isSubscription', res.value);
        if (res.value === 'true') {
          this.subscribedUser = true;
        } else {
          this.subscribedUser = false;
        }
        this.checkSubscription();
        this.cdr.detectChanges();
      }
    });
  }


  ngOnDestroy() {
    localStorage.removeItem('verified-card');
  }

  checkDeviceLink(params) {
    if (params.slug) {
      this.cardSlug = params.slug;
      if (iPhoneDevices.includes(navigator.platform)) {
        window.location.href = `${environment.device.iPhone}/${params.type}/${params.slug}/${params.pid}`;
        setTimeout(() => {
          this.loader = false;
          this.getCard(params);
        }, 3000);
      } else if (androidDevices.includes(navigator.platform)) {
        window.location.href = `${environment.device.android}/${params.type}/${params.slug}/${params.pid}`;
        setTimeout(() => {
          this.loader = false;
          this.getCard(params);
        }, 3000);
      } else {
        this.loader = false;
        this.getCard(params);
      }
    }
  }

  getCard(params) {
    let cardInfo = {
      slug: params.slug,
      pid: params.pid ? params.pid : '',
      type: params.type,
      username: this.username ? this.username : '',
      email: this.email ? this.email : '',
      phone: this.intPhoneValue + this.smsNumber,
      uid: localStorage.getItem('userID') || ''
    };

    if (localStorage.getItem('token') && localStorage.getItem('userID')) {
      cardInfo = Object.assign({
      }, cardInfo);
    }
    this.cardsService.getSharedCard(cardInfo).then(res => {
      if (res) {
        this.loader = false;
        this.cardDetail = res;
        this.isData = true;

        this.cardDetail["positionAt"] = this.cardDetail["positionTitle"];
        
        if(this.cardDetail["positionAt"] && this.cardDetail["organizationName"])
          this.cardDetail["positionAt"] += ` at ${this.cardDetail["organizationName"]}`;

        if(this.cardDetail["phoneNumber"])
          this.cardDetail["formattedPhoneNumber"] = this.formatTelephone(this.cardDetail["phoneNumber"]);

        this.cardDetail["cardImage"] = this.cardDetail["image"] ? this.getFile(this.cardDetail["image"]) : "assets/images/default_card.png";        
        this.cardDetail["userImage"] = this.cardDetail["user"]["profileImage"] ? this.getFile(this.cardDetail["user"]["profileImage"]) : "assets/images/no-image.jpeg";

        let logo = this.cardDetail["logo"]; 
        
        if(!logo || logo.indexOf("default_card.png") > -1)
          logo = "assets/images/default_card.png";
        
        this.cardDetail["cardLogo"] = logo; 

        if(this.cardDetail["audio"])
          this.initializeAudioPlayer(this.cardDetail["audio"]);

        this.checkSelf();
        this.cdr.detectChanges();
        this.setVcard(this.cardDetail);
        if (this.cardDetail['groupId']) {
          this.selectedGroup = this.cardDetail['groupId'];
        }        
      }
    }, error => {
      this.toastr.error(error.json().message);
      this.loader = false;
      this.isError = true;
      this.cdr.detectChanges();
    });
  }


  checkSelf() {
    if (this.cardDetail['userId'] === localStorage.getItem('userID')) {
      this.thirdPartyShare = false;
    } else {
      this.thirdPartyShare = true;
    }
    this.cdr.detectChanges();
  }

  setVcard(_cardDetails) {
    const name = _cardDetails.name.split(' ');
    const cardData = {
      name: {
        firstNames: name[0],
        lastNames: name[1] || ''
      },
      // email: _cardDetails.email,
      workemail: _cardDetails.email,
      workPhone: `${_cardDetails.phoneNumber.code}${_cardDetails.phoneNumber.number}`,
      organization: _cardDetails.organizationName,
      title: _cardDetails.positionTitle,
      note: _cardDetails.address,
      photo: this.getFile(_cardDetails.logo),
      url: _cardDetails.websiteUrl,
      nickname: `${_cardDetails.firstName} ${_cardDetails.lastName}`,
      role: _cardDetails.positionTitle,
      'socialUrls.facebook': _cardDetails.fbProfileLink,
      homePhone: `${_cardDetails.phoneNumber.code}${_cardDetails.phoneNumber.number}`
    };
    Object.keys(cardData).filter(_i => _i !== 'photo').map(_ => this.vCard[_] = cardData[_]);
    // cardData.hasOwnProperty('photo') ? this.vCard.photo.attachFromUrl(cardData['photo']) : null;
  }

  getFile(_path) {
    if (_path) {
      return this.commonService.getFile(_path);
    }
  }

  /** modal */

  createForm() {
    this.privateForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', Validators.email],
      code: [''],
      number: ['']
    });
  }

  get f() { return this.privateForm.controls; }

  saveDetail() {
    let cardInfo = {
      // id: this.cardDetail['_id'],
      slug: this.paramsValues.slug,
      type: this.paramsValues.type,
      pid: this.paramsValues.pid,
      privateId: this.paramsValues.pid,
      username: `${this.f.firstName.value} ${this.f.lastName.value}`,
      toFirstName: this.f.firstName.value, 
      toLastName: this.f.lastName.value, 
      email: this.email || '',
      toEmail: this.email ||  '',
      phone: this.intPhoneValue + this.smsNumber,
      phoneNumber: {
        code: this.intPhoneValue,
        countryCode: this.intPhoneValue,
        number: this.smsNumber
      },
      toPhoneNumber: {
        code: this.intPhoneValue, 
        countryCode: this.intPhoneValue, 
        number: this.smsNumber
      },
      uid: localStorage.getItem('userID') || '', 
      userId: localStorage.getItem('userID') || '', 
      cardViewType: "mobile",// this.paramsValues.cardViewType,
      sharerId: this.paramsValues.userId,
    };
    if (localStorage.getItem('token') && localStorage.getItem('userID')) {
      cardInfo = Object.assign({
        uid: localStorage.getItem('userID'),
        userId: localStorage.getItem('userID')
      }, cardInfo);
    }
    this.cardsService.getSharedCard(cardInfo).then(res => {
      if (res) {
        this.cardDetail = res;
        localStorage.setItem('verified-card', this.paramsValues.slug);
        this.checkDeviceLink(this.paramsValues);
        this.cdr.detectChanges();
        /*TODO: revisit this flow*/
        this.favourite = this.cardDetail['isFavorite'];
        this.modalService.dismissAll();
        this.cardVerification = true;
      }
    }, error => {
      this.toastr.error(error.json().message);
    });
  }

  /** Connect */
  openConnect(connectModal) {
    this.modalService.open(connectModal);
    this.fetchGroups();
  }

  openReport(reportModal) {
    this.modalService.open(reportModal);
  }

  closeModal() {

    /** reset values */
    this.groupList = [];
    this.connectCard = '';
    this.modalService.dismissAll();
  }

  saveCardBy(saveBy) {
    this.connectCard = saveBy;
  }

  fetchGroups() {
    this.groupService.getGroups().then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.groupList = res.data;
      }
    }, (error) => {
      this.toastr.error(error.message);
    });
  }

  saveCard(_type) {
    let obj = {
      slug: this.cardSlug,
      groupId: this.selectedGroup ? this.selectedGroup : '',
      type: _type
    };
    if (_type === 1 && this.cardNote) {
      obj = Object.assign({
        notes: this.cardNote
      }, obj);
    }
    this.cardsService.action(obj).then(res => {
      if (res) {
        if (_type === 2 || _type === 4) {
          this.favourite = _type === 2;
        }
        this.cdr.detectChanges();
        this.toastr.success(res.message);
        setTimeout(() => {
          this.closeModal();
        }, 500);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  isLoggedIn() {
    if (localStorage.getItem('token') && localStorage.getItem('userID')) {
      return true;
    } else {
      return false;
    }
  }

  /** open share modal*/

  openShare(_shareType) {
    this.shareMode = _shareType;
    this.createForm();
    this.title = 'Share';
    this.email = '';
    this.smsNumber = '';
    this.shareType = _shareType;
    if (!this.pid) {
      this.pid = this.commonService.generateRandom();
    }

    if (this.shareType) {
      this.modalService.open(this.shareModal);
      this.publicShare = _shareType === 'public' ? true : false;
    }
  }

  shareCard(_card, shareType) {
    this.shareType = shareType;
  }
  /*********** Update share card information *************/
  sharedCard(pid) {
    const share = {
      id: this.cardDetail['_id'],
      pid,
      username: `${this.f.firstName.value} ${this.f.lastName.value}`,
      email: this.shareType === 'email' ? this.email : '',
      phone: this.shareType !== 'email' ? this.intPhoneValue + this.smsNumber : '',
      mediaType: this.shareType
    };
    this.cardsService.shareCard(share).then(res => {
      if (res) {
        this.modalService.dismissAll();
      }
    }, error => {
      this.modalService.dismissAll();
      this.toastr.error(error.json().message);
    });
  }

  shareCardPublic(type) {
    const share = {
      id: this.cardDetail['_id'],
      mediaType: type
    };
    this.cardsService.shareCard(share);
  }

  shareCardPrivate(_card) {
    this.process = true;
    this.SHARE_LINK = this.shareUrl + 'm/' + _card['slug'] + '/' + this.pid;
    if (this.shareMode === 'thirdParty') {
      this.thirdParty();
    } else {
      this.sharedCard(this.pid);
    }

    let message = {
      message: this.SHARE_LINK,
      username: `${this.f.firstName.value} ${this.f.lastName.value}`,
      type: this.shareType
    };
    if (this.shareType === 'sms') {
      message = Object.assign({
        number: this.intPhoneValue + this.smsNumber
      }, message);
    } else {
      message = Object.assign({
        email: this.email
      }, message);
    }
    this.cardsService.sendSms(message).then(response => {
      if (response) {
        this.toastr.success(response.message);
        this.modalService.dismissAll();
        this.process = false;
      }
    }, error => {
      this.toastr.error(error.json().message);
      this.process = false;
    });
  }



  /** third party share method */
  thirdParty() {
    let cardInfo = {
      slug: this.cardDetail['slug'],
      pid: this.pid,
      username: this.f.firstName.value + ' ' + this.f.lastName.value,
      mediaType: this.shareType,
    };

    if (this.shareType === 'sms') {
      cardInfo = Object.assign({
        phone: this.intPhoneValue + this.smsNumber
      }, cardInfo);
    } else {
      cardInfo = Object.assign({
        email: this.email
      }, cardInfo);
    }
    this.cardsService.thirdPartyShare(cardInfo);
  }

  shareCardSms(_card) {
    this.process = true;
    this.SHARE_LINK = this.shareUrl + 'm/' + _card['slug'] + '/' + this.pid;
    const message = {
      number: this.intPhoneValue + this.smsNumber,
      message: this.SHARE_LINK
    };
    this.cardsService.sendSms(message).then(response => {
      if (response) {
        this.toastr.success(response.message);
        this.process = false;
      }
    }, error => {
      this.toastr.error(error.json().message);
      this.process = false;
    });
  }

  /** audio play count method */
  audioCount(cardId) {
    if (cardId) {
      const card = { id: cardId };
      this.cardsService.audioPlayCount(card);
    }
  }

  initializeAudioPlayer(audioMessage: string) {
    if(audioMessage) {
      this.audioPlayer = new Audio(this.commonService.getFile(audioMessage));

      this.audioPlayer.oncanplaythrough = (event) => {
        this.isAudioReady = true; 
      };

      this.audioPlayer.ondurationchange = (event) => {
        if(!Number.isNaN(this.audioPlayer.duration)) {
          let seconds = Math.floor(this.audioPlayer.duration % 60); 

          this.audioLength = `0${Math.floor(this.audioPlayer.duration / 60)}:${seconds > 9 ? seconds : `0${seconds}`}`;

          this.cdr.detectChanges();
        }
      };

      this.audioPlayer.ontimeupdate = (event) => {
        if(!Number.isNaN(this.audioPlayer.currentTime)) {
          let seconds = Math.floor(this.audioPlayer.currentTime % 60); 

          this.currentAudioTime = `0${Math.floor(this.audioPlayer.currentTime / 60)}:${seconds > 9 ? seconds : `0${seconds}`}`;
          this.audioProgress = (Math.floor(this.audioPlayer.currentTime) / Math.floor(this.audioPlayer.duration)) * 100;

          this.cdr.detectChanges();
        }
      };

      this.audioPlayer.onended = (event) => {
        this.toggleAudio("stop");
      };
    }
  }

  toggleAudio(action: string) {
    if(this.audioPlayer) {
      switch(action) {
        case "play": 
          if(this.isAudioReady) {
            this.audioPlayer.play();
            this.isPlayingAudio = true;

            if(this.shouldCountAudioPlay) {
                this.shouldCountAudioPlay = false; 

                this.audioCount(this.cardDetail["id"]);
            }
          }

          break; 
        case "pause": 
          this.audioPlayer.pause();
          this.isPlayingAudio = false; 

          break; 
        case "stop": 
          if(!Number.isNaN(this.audioPlayer.currentTime) && this.audioPlayer.currentTime > 0) {
            this.currentAudioTime = "00:00";
            this.isPlayingAudio = false; 
            this.audioProgress = 0; 
            this.audioPlayer.pause();
            this.audioPlayer.currentTime = 0;
            this.shouldCountAudioPlay = true; 
          }
      }
    }
  }

  coutAudioPlay(_card) {
    if (_card) {
      this.audioPlayer = new Audio(this.commonService.getFile(_card.audio));
      this.process = true;
      this.audioPlayer.play();
    } else {
      return;
    }
    if (_card['userId'] !== localStorage.getItem('userID')) {
      this.cardsService.audioPlayCount({ id: _card._id });
    }
  }

  stopAudio(cardAudio) {
    this.audioPlayer.src = '';
    this.process = false;
    this.audioPlayer = new Audio(this.commonService.getFile(cardAudio));
    this.audioPlayer.pause();
  }

  getCountryCode() {
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.intPhoneValue = this.country[0]['dial_code'];
      }
    );
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  checkSubscription() {
    if (localStorage.getItem('isSubscription') !== 'true') {
      this.subscribedUser = true;
      this.adService.getAds().then(res => {
        const data = res.data;
        if (data && data.length) {
          this.advertisement = data.map(add => {
            return new AdItem(HeroProfileComponent,
              {
                name: add.name,
                bio: add.description,
                img: this.getFile(add.image),
                link: add.link
              });
          });
        }
      });
    } else {
      this.subscribedUser = false;
    }
  }

  viewNote(notes) {
    this.modalService.open(notes);
  }

  downloadCsv() {
    const cardData = this.omitObject(this.cardDetail);
    const currentTimestamp = new Date().getTime();
    this.commonService.exportCsv(cardData, `CardExport-${currentTimestamp}`);
  }

  omitObject(data) {
    return [{
      first_name: data.firstName ? data.firstName : data.user.firstName,
      last_name: data.lastName ? data.lastName : data.user.lastName,
      email: data.uemail,
      business_email: data.email,
      name: data.name,
      organization_name: data.organizationName,
      position: data.positionTitle,
      website: data.websiteUrl,
      logo: data.logo,
      address: data.address,
      phone: `${data.phoneNumber.code}-${data.phoneNumber.number}`,
      fb_link: data.fbProfileLink,
      linkdIn_link: data.linkdInProfileLink,
      youtube_link: data.youTubeChannelLink,
      twitter_link: data.twitterProfileLink,
      instagram_link: data.instagramProfileLink,
      image: data.image
    }];
  }

  getCardLinks(path) {
    if (path.includes('http', 'https')) {
      return path;
    } else {
      return `https://${path}`;
    }
  }

  trackUrlHit(type) {
    if (type && (this.cardDetail['userId'] !== localStorage.getItem('userID'))) {
      const card = {
        id: this.cardDetail['_id'],
        type: type
      };
      this.cardsService.trackUrlHit(card);
    } else {
      return;
    }
  }

  vCardDownload() {
    window.location.href = this.cardUrl + '/api/v1/card/vCard/' + this.cardDetail['id'];
  }

  formatTelephone(phoneNumber : any) : string
  {
      if(phoneNumber && phoneNumber.telephone > 0)
      {
          let phone = phoneNumber.telephone as string;

          if(phone.length == 7)
              phone = phone.replace(phone.charAt(3), `-${phone.charAt(3)}`); 
          else 
              if(phone.length == 10)
                  phone = phone.replace(phone.charAt(6), `-${phone.charAt(6)}`).replace(phone.charAt(3), `-${phone.charAt(3)}`);

          return `(+${phoneNumber.code}) ${phone}`;
      }

      return "";
  }
}
