import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from './../../shared/filter.pipe';
import { GroupService } from './../../services/group.service';
import { ToastrService } from 'ngx-toastr';
import { userStatus } from './../../../i18n/constants';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  providers: [GroupService]
})
export class GroupComponent implements OnInit {
  busy: Promise<any>;
  modalRef: any;
  search: String;
  deleteRef: any;
  data: any;
  users: any;
  page = 1;
  totalItems = 0;
  itemsPerPage = 10;
  loader: Boolean = true;
  showSort: Boolean = true;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;
  editableId: String = '';
  deleteId: String = '';
  sortRequestField: String = '';
  sortRequestOrder: String = '';
  sortableData: any;
  searchableData: any;
  searchableList = ['name'];
  defaultSort: String = 'name';
  column: Array<any> = [
    { id: 'name', name: 'Group Name', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { id: 'description', name: 'Description', sorting: false, type: 'string', display: 'left' },
    { name: 'Action', sorting: false, type: 'string', display: 'left' }
  ];
  actions: Array<any>;
  isEdit: Boolean = true;
  isDelete: Boolean = false;
  @ViewChild('content') content: ElementRef;
  @ViewChild('delete') delete: ElementRef;
  public model = {
    name: '',
    description: '',
    requestStatus: 5,  // Default (All Group) data listing
    search: ''
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _service: GroupService,
  ) { }

  ngOnInit() {
    this.getGroups(); // To get user's details
    this.actions = [{
      parentClass: "btn-success",
      action: (item) => this.openEditModal(this.content, item['_id']),
      title: "Edit",
      childClass: "mdi-marker"
    }, {
      parentClass: "btn-danger",
      action: (item) => this.openDeleteModal(this.delete, item['_id']),
      title: "Delete",
      childClass: "mdi-delete"
    }];
  }

  getGroups(reload = false) {
    this.busy = this._service.getGroups().then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.data = res.data;
      } else {
        this.data = [];
      }
      this.totalItems = this.data.length;
      this.loader = false;
      this.sliceList(this.page, this.data);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  searchUser() {
    if (this.search && this.search.trim().length) {

      this.users = this.data.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
      this.totalItems = this.users.length;
      this.page = 1;
      this.sliceList(this.page, this.users);
    } else {
      this.totalItems = this.data.length;
      this.page = 1;
      this.sliceList(this.page, this.data);
    }
  }

  searchReset() {
    this.search = '';
    this.searchUser();
  }

  sliceList(page, data) {
    let start = (page - 1) * this.itemsPerPage;
    let end = start + this.itemsPerPage;
    this.users = data.slice(start, end);
  }

  sortData(field, order) {
    this.column = this.column.map(x => field === x['id'] ? { ...x, 'order': order } : x);
    if (order == 'asc') {
      this.users = this.users.sort((a, b) => a[field].localeCompare(b[field]))
    } else {
      this.users = this.users.sort((a, b) => b[field].localeCompare(a[field]))
    }
  }

  onPageChange(page) {
    if (this.search && this.search.length) {
      this.sliceList(page, this.users);
    } else {
      this.sliceList(page, this.data);
    }
  }

  deleteGroup() {
    this._service.delete(this.deleteId).then((res) => {
      this.getGroups();
      this.deleteRef.close();
      return this.toastr.success(res.message);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      this.deleteRef.close();
      return this.toastr.error(errJson.message);
    });
  }

  openDeleteModal(content, id) {
    this.deleteId = id;
    this.deleteRef = this.modalService.open(content);
  }

  addEditGroup() {
    if (this.editableId) {
      this.editGroup();
    } else {
      this.addGroup();
    }
  }


  editGroup() {
    this.submitBtn = true;
    this.cancelBtn = true;
    const user = {
      id: this.editableId,
      name: this.model.name,
      description: this.model.description,
    };
    this.busy = this._service.update(user).then((res) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      this.getGroups();
      this.modalRef.close();
      this.model.name = '';
      this.model.description = '';
      return this.toastr.success(res.message);
    }, (error) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      let errJson = JSON.parse(error._body);
      this.modalRef.close();
      return this.toastr.error(errJson.message);
    });
  }

  addGroup() {
    this.submitBtn = true;
    this.cancelBtn = true;
    const user = {
      name: this.model.name,
      description: this.model.description,
    };
    this.busy = this._service.addGroup(user).then((res) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      this.getGroups();
      this.modalRef.close();
      this.model.name = '';
      this.model.description = '';
      return this.toastr.success(res.message);
    }, (error) => {
      this.submitBtn = false;
      this.cancelBtn = false;
      let errJson = JSON.parse(error._body);
      this.modalRef.close();
      return this.toastr.error(errJson.message);
    });
    //}

  }

  openEditModal(content, groupId) {
    const group = this.users.find(row => row._id === groupId);
    this.model.name = group.name;
    this.model.description = group.description;
    this.editableId = groupId;
    this.modalRef = this.modalService.open(content);
  }

  open(content) {
    this.editableId = undefined;
    this.model.name = '';
    this.model.description = '';
    this.modalRef = this.modalService.open(content);
  }

}
