import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from '../../services/profile.service';
import Messages from './../../../i18n/messages';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ProfileService]
})

export class ChangePasswordComponent implements OnInit {
  @ViewChild('f') formValues;
  busy: Promise<any>;
  public model = {
    password: null,
    oldpassword: null,
    confpassword: null
  };

  constructor(
    private _router: Router,
    private _service: ProfileService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

  }

  changePassword() {
    if (this.model.password === this.model.confpassword) {
      const reqParams = { oldPassword: this.model.oldpassword, password: this.model.password };
      this.busy = this._service.changePassword(reqParams).then(
        (res: any) => {
          this.formValues.resetForm();
          this.toastr.success(res.message);
        },
        (error) => {
          this.toastr.error(error.json().message);
          // if (error.status && error.statusText) {
          //   this.toastr.error(error.statusText);
          // } else {
          //   this.toastr.error(Messages.passwordUnable);
          // }
        }
      );
    } else {
      return this.toastr.error(Messages.passwordUnmatch);
    }
  }
}
