import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CardsService } from '../../../services/card.service';
import { GroupService } from '../../../services/group.service';
import { CommonService } from '../../../services/common.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VCard } from 'ngx-vcard';
import { AdService } from '../../../shared/ad/ad.service';
import { AdItem } from '../../../shared/ad/ad-item';
import { HeroProfileComponent } from '../../../shared/ad/hero-profile.component';

@Component({
  selector: 'app-view-card',
  templateUrl: './view-card.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class ViewCardComponent implements OnInit {
  shareUrl = environment.api.shareCardLink;
  cardUrl = environment.api.card;
instaurl = 'https://www.instagram.com/'
  loader: Boolean = true;
  cardDetail: Object = {};
  SHARE_LINK = '';
  preview: Boolean = false;
  toPreview: any = '';
  cardList: Array<object> = [];
  smsNumber: String = '';
  intPhoneValue: any;
  country: String[];
  publicShare: Boolean = false;
  privateForm: FormGroup;
  shareType: any;
  shareMode: any;
  pid: String = '';
  email: String;
  paramType: any = 1;
  _card: object = {};
  groupList: Array<object> = [];
  selectedGroup: String = '';
  shareCardTitle = 'Request for sharing card';
  cardNote = '';
  spinner = false;
  favourite: Boolean = false;

  subscribedUser: Boolean = false;
  advertisement: AdItem[];

  // linkedInUrl = '';

  process: Boolean = false;
  audioPlayer = new Audio();
  public vCard: VCard = {};
  constructor(config: NgbModalConfig,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private httpService: HttpClient,
    private fb: FormBuilder,
    private cardsService: CardsService,
    private groupService: GroupService,
    private adService: AdService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.getCountryCode();
    this.checkSubscription();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params && params.type) {
        this.paramType = params.type;
      }
    });
    this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id === '1' || id === '2') {
        this.preview = true;
        this.toPreview = id;
        this.loader = false;
      } else {
        this.preview = false;
        this.cardsService.getCardById(id).then(res => {
          if (res) {
            this.cardDetail = res;
            this.cardNote = this.cardDetail['notes'];
            // this.linkedInUrl =  'https%3A%2F%' + this.shareUrl + 'l/' + this.cardDetail['slug'];
            this.setVcard(this.cardDetail);
            this.loader = false;
            this.cdr.detectChanges();
            this.favourite = this.cardDetail['isFavorite'];

            this.cardDetail["positionAt"] = this.cardDetail["positionTitle"];
            
            if(this.cardDetail["positionAt"] && this.cardDetail["organizationName"])
              this.cardDetail["positionAt"] += ` at ${this.cardDetail["organizationName"]}`;

            if(this.cardDetail["phoneNumber"])
              this.cardDetail["formattedPhoneNumber"] = this.formatTelephone(this.cardDetail["phoneNumber"]);

            this.cardDetail["cardImage"] = this.cardDetail["image"] ? this.getFile(this.cardDetail["image"]) : "assets/images/default_card.png";
            this.cardDetail["cardLogo"] = this.cardDetail["logo"] ? this.getFile(this.cardDetail["logo"]) : "assets/images/default_card.png";
          }
        }, error => {
          this.loader = false;
          this.toastr.error(error.json().message);
        });
      }
    });
  }

  setVcard(_cardDetails) {
    const name = _cardDetails.name.split(' ');
    const cardData = {
      name: {
        firstNames: name[0],
        lastNames: name[1] || ''
      },
      // email: _cardDetails.email,
      workemail: _cardDetails.email,
      workPhone: `${_cardDetails.phoneNumber.code}${_cardDetails.phoneNumber.number}`,
      organization: _cardDetails.organizationName,
      title: _cardDetails.positionTitle,
      note: _cardDetails.address,
      photo: this.getFile(_cardDetails.logo),
      url: _cardDetails.websiteUrl,
      nickname: `${_cardDetails.firstName} ${_cardDetails.lastName}`,
      role: _cardDetails.positionTitle,
      'socialUrls.facebook': _cardDetails.fbProfileLink,
      homePhone: `${_cardDetails.phoneNumber.code}${_cardDetails.phoneNumber.number}`
    };
    Object.keys(cardData).filter(_i => _i !== 'photo').map(_ => this.vCard[_] = cardData[_]);
    // cardData.hasOwnProperty('photo') ? this.vCard.photo.attachFromUrl(cardData['photo']) : null;
  }

  getFile(_path) {
    if (_path) {
      return this.commonService.getFile(_path);
    }
  }

  getCountryCode() {
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.intPhoneValue = this.country[0]['dial_code'];
      }
    );
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  openReport(reportModal) {
    this.modalService.open(reportModal);
  }

  /** share */
  openShare(shareModal, shareMode) {
    this.shareMode = shareMode;
    this.shareType = shareMode;
    this.process = false;
    if (!this.pid) {
      this.pid = this.commonService.generateRandom();
    }

    if (shareMode && shareMode === 'public') {
      this.publicShare = true;
    } else {
      this.publicShare = false;
      this.createForm();
    }
    // this.SHARE_LINK = this.shareUrl + 'e/' + this.cardDetail['slug'] + '/' + this.pid;
    this.modalService.open(shareModal);
  }

  createForm() {
    this.privateForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      email: ['', Validators.email],
      code: [''],
      number: ['']
    });
  }

  openSmsShare(card, smsShareModal) {
    this.smsNumber = '';
    this.modalService.open(smsShareModal);
  }

  closeModal() {

    /** reset values */
    this.shareType = '';
    this.intPhoneValue = '';
    this.smsNumber = '';

    this.modalService.dismissAll();
    this.spinner = false;
  }

  shareCard(_card, shareType) {
    this.shareType = shareType;
  }

  /*********** Update share card information *************/
  sharedCard(pid) {
    const share = {
      id: this.cardDetail['_id'],
      pid,
      username: `${this.f.firstName.value} ${this.f.lastName.value}`,
      email: this.shareType === 'email' ? this.email : '',
      phone: this.shareType !== 'email' ? this.intPhoneValue + this.smsNumber : '',
      mediaType: this.shareType
    };
    this.cardsService.shareCard(share);
  }

  shareCardPublic(type) {
    const share = {
      id: this.cardDetail['_id'],
      mediaType: type
    };
    this.cardsService.shareCard(share);
  }

  shareCardPrivate(_card, type) {
    this.process = true;
    let shareData = {};
    this.SHARE_LINK = this.shareUrl + 'm/' + _card['slug'] + '/' + this.pid;

    if (this.shareMode === 'thirdParty') {
      this.thirdPartyShare();
    } else {
      this.sharedCard(this.pid);
    }
    shareData = {
      message: this.SHARE_LINK,
      username: this.f.firstName.value + this.f.lastName.value,
      type: this.shareType
    };
    if (this.shareType === 'sms') {
      shareData = Object.assign({
        number: this.intPhoneValue + this.smsNumber
      }, shareData);
    } else {
      shareData = Object.assign({
        email: this.email
      }, shareData);
    }
    this.cardsService.sendSms(shareData).then(response => {
      if (response) {
        this.toastr.success(response.message);
        this.modalService.dismissAll();
        this.process = false;
      }
    }, error => {
      this.toastr.error(error.json().message);
      this.process = false;
    });
  }

  /** third party share method */
  thirdPartyShare() {
    let cardInfo = {
      slug: this.cardDetail['slug'],
      pid: this.pid,
      username: this.f.firstName.value + ' ' + this.f.lastName.value,
      mediaType: this.shareType,
    };

    if (this.shareType === 'sms') {
      cardInfo = Object.assign({
        phone: this.intPhoneValue + this.smsNumber
      }, cardInfo);
    } else {
      cardInfo = Object.assign({
        email: this.email
      }, cardInfo);
    }
    this.cardsService.thirdPartyShare(cardInfo);
  }

  get f() { return this.privateForm.controls; }

  viewNote(notes) {
    this.modalService.open(notes);
  }

  checkEdit(_card) {
    if (_card && _card['userId'] === localStorage.getItem('userID')) {
      return true;
    } else {
      return false;
    }
  }

  coutAudioPlay(_card) {
    if (_card) {
      this.audioPlayer = new Audio(this.commonService.getFile(_card.audio));
      this.process = true;
      this.audioPlayer.play();
    } else {
      return;
    }
    if (!this.checkEdit(_card)) {
      this.cardsService.audioPlayCount({ id: _card._id });
    }
  }

  stopAudio(cardAudio) {
    this.audioPlayer.src = '';
    this.process = false;
    this.audioPlayer = new Audio(this.commonService.getFile(cardAudio));
    this.audioPlayer.pause();
  }

  checkSubscription() {
    if (localStorage.getItem('isSubscription') === 'true') {
      this.subscribedUser = true;
      // this.adService.getAds().then(res => {
      //   const data = res.data;
      //   if (data && data.length) {
      //     this.advertisement = data.map(add => {
      //       return new AdItem(HeroProfileComponent,
      //         {
      //           name: add.name,
      //           bio: add.description,
      //           img: this.getFile(add.image),
      //           link: add.link
      //         });
      //     });
      //   }
      // });
    } else {
      this.subscribedUser = false;
    }
  }

  openGroup(card, groupModal) {
    this._card = card;
    if (this.groupList && this.groupList.length <= 0) {
      this.fetchGroups();
    }
    this.modalService.open(groupModal);
  }

  groupSelection(e) {
    this.selectedGroup = e.target.value;
  }

  changeGroup(type) {
    this.spinner = true;
    const obj = {
      id: this.cardDetail['_id'],
      groupId: this.selectedGroup ? this.selectedGroup : this.cardDetail['groupId'],
      notes: this.cardNote ? this.cardNote : this.cardDetail['notes']
    };
    this.cardsService.changeGroup(obj).then(res => {
      this.modalService.dismissAll();
      this.spinner = false;
      this.toastr.success(res.message);
    }, error => {
      // this.modalService.dismissAll();
      this.spinner = false;
      this.toastr.error(error.json().message);
    });
  }

  fetchGroups() {
    this.groupService.getGroups().then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.groupList = res.data;
      }
    }, (error) => {
      this.toastr.error(error.message);
    });
  }

  favouriteAction(type) {
    const obj = {
      slug: this.cardDetail['slug'],
      groupId: this.cardDetail['groupId'],
      type: type
    };
    this.cardsService.action(obj).then(res => {
      if (res) {
        if (type === 2 || type === 4) {
          this.favourite = type === 2;
        }
        // this.ngOnInit();
        // this.cdr.detectChanges();
        this.modalService.dismissAll();
        this.toastr.success(res.message);
      }
    }, error => {
      this.toastr.error(error.json().message);
    });
  }

  downloadCsv() {
    const cardData = this.omitObject(this.cardDetail);
    const currentTimestamp = new Date().getTime();
    this.commonService.exportCsv(cardData, `CardExport-${currentTimestamp}`);
  }
  omitObject(data) {
    return [{
      first_name: data.firstName,
      last_names: data.lastName,
      email: data.uemail,
      business_email: data.email,
      name: data.name,
      organization_name: data.organizationName,
      position: data.positionTitle,
      website: data.websiteUrl,
      logo: data.logo,
      address: data.address,
      phone: `${data.phoneNumber.code}-${data.phoneNumber.number}`,
      fb_link: data.fbProfileLink,
      linkdIn_link: data.linkdInProfileLink,
      youtube_link: data.youTubeChannelLink,
      twitter_link: data.twitterProfileLink,
      instagram_link: data.instagramProfileLink,
      image: data.image
    }];
  }

  getCardLinks(path) {
    if (path.includes('http', 'https')) {
      return path;
    } else {
      return `https://${path}`;
    }
  }

  trackUrlHit(type) {
    if (type && (this.cardDetail['userId'] !== localStorage.getItem('userID'))) {
      const card = {
        id: this.cardDetail['_id'],
        type: type
      };
      this.cardsService.trackUrlHit(card);
    } else {
      return;
    }
  }

  vCardDownload() {
    window.location.href = this.cardUrl + '/vCard/' + this.cardDetail['_id'];
  }

  linkedInShare(_url) {
    const url = this.shareUrl + 'l/' + this.cardDetail['slug'];
    window.open('http://www.linkedin.com/shareArticle?url='
      + encodeURIComponent(url), '', 'left=0,top=0,width=650,height=420,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }

  formatTelephone(phoneNumber : any) : string
  {
      if(phoneNumber && phoneNumber.telephone > 0)
      {
          let phone = phoneNumber.telephone as string;

          if(phone.length == 7)
              phone = phone.replace(phone.charAt(3), `-${phone.charAt(3)}`); 
          else 
              if(phone.length == 10)
                  phone = phone.replace(phone.charAt(6), `-${phone.charAt(6)}`).replace(phone.charAt(3), `-${phone.charAt(3)}`);

          return `(+${phoneNumber.code}) ${phone}`;
      }

      return "";
  }

}
