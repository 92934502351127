import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardsService } from '../../../services/card.service';
import { CommonService } from '../../../services/common.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
// import { error } from '@angular/compiler/src/util';
import { HttpClient } from '@angular/common/http';
import { FilterPipe } from './../../../shared/filter.pipe';
import { GroupService } from '../../../services/group.service';

// const SHARE_LINK = environment.api.website + '/digital-card/:type/';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class ListCardComponent implements OnDestroy, OnInit {

  SHARE_LINK = environment.api.shareCardLink;

  cardList: Array<object> = [];
  loader: Boolean;
  _card: object = {};
  groupId = '';
  groupList: Array<object> = [];
  selectedGroup: String = '';

  smsNumber: String = '';
  intPhoneValue: any;
  country: String[];
  requestType: Number;
  search: any;

  page = 1;
  totalItems = 0;
  itemsPerPage = 6;

  process: Boolean = false;
  audioPlayer = new Audio();
  spinner = false;

  constructor(config: NgbModalConfig,
    private commonService: CommonService,
    private cardsService: CardsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpClient,
    private groupService: GroupService,
    private router: Router
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }


  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params.type) {
        this.requestType = params.type;
        this.getCards();
        this.fetchGroups();
      } else {
        return;
      }
    });
  }

  getCards() {
    this.loader = true;
    const obj = {
      type: this.requestType,
      page: this.page,
      search: this.search,
      groupId: this.groupId ? this.groupId : ''
    };
    this.cardsService.getCards(obj).then(res => {
      if (res && res.data) {
        this.cardList = res.data;//['list'];
        this.totalItems = res.data.length;//['total'];
        setTimeout(() => {
          this.loader = false;
        }, 500);
      }
    }, error => {
      this.toastr.error(error.json().message);
      this.loader = false;
    });
  }


  searchReset() {
    this.search = '';
    this.getCards();
  }

  fetchGroups() {
    this.groupService.getGroups().then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.groupList = res.data;
      }
    }, (error) => {
      this.toastr.error(error.message);
    });
  }


  getFile(_path) {
    if (_path) {
      return this.commonService.getFile(_path);
    }
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  openGroup(card, groupModal) {
    this._card = card;
    this.modalService.open(groupModal);
  }

  groupSelection(e) {
    this.selectedGroup = e.target.value;
  }

  changeGroup() {
    this.spinner = true;
    const obj = {
      id: this._card['_id'],
      groupId: this.selectedGroup,
      notes: this._card['notes'] ? this._card['notes'] : ''
    };
    this.cardsService.changeGroup(obj).then(res => {
      this.spinner = false;
      this.modalService.dismissAll();
      this.toastr.success(res.message);
    }, error => {
      this.spinner = false;
      this.modalService.dismissAll();
      this.toastr.error(error.json().message);
    });
  }

  openDelete(card, deleteModal) {
    this._card = card;
    this.modalService.open(deleteModal);
  }

  deleteCard(cardId) {
    this.spinner = true;
    this.cardsService.deleteCardById(cardId).then(res => {
      if (res && res.statusCode === 200) {
        setTimeout(() => {
          this.spinner = false;
          this.toastr.success(res.message);
          this.modalService.dismissAll();
          this.ngOnInit();
        }, 500);
      }
    }, error => {
      this.modalService.dismissAll();
      this.toastr.error(error.json().message);
    });
  }

  /** share */
  openShare(shareModal) {
    this.modalService.open(shareModal);
  }

  openSmsShare(card, smsShareModal) {
    this.getCountryCode();
    this.smsNumber = '';
    this._card = card;
    this.modalService.open(smsShareModal);
  }

  getCountryCode() {
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.intPhoneValue = this.country[0]['dial_code'];
      }
    );
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  shareSms(cardId) {
    this.spinner = true;
    this.SHARE_LINK = this.SHARE_LINK + 'm/' + this._card['slug'];
    const message = {
      number: this.intPhoneValue + this.smsNumber,
      message: this.SHARE_LINK
    };
    this.cardsService.sendSms(message).then(res => {
      if (res) {
        this.spinner = false;
        this.toastr.success(res.message);
      }
    }, error => {
      this.spinner = false;
      this.toastr.error(error.json().message);
    });
  }

  getTitle() {
    if (this.requestType && (this.requestType == 1)) {
      return 'All cards';
    } else if (this.requestType && (this.requestType == 2)) {
      return 'Saved cards';
    } else if (this.requestType == 3) {
      return 'Favorite Cards';
    } else if (this.requestType == 4) {
      return `Organization's Users' Cards`;
    } else if (this.requestType == 5) {
      return 'Organization Cards';
    } else {
      return 'User cards';
    }
  }

  isLoggedIn(cardData) {
    if (localStorage.getItem('userID') === cardData.userId) {
      return true;
    } else {
      return false;
    }
  }
  /** filter cards */

  // searchCard() {
  //   if (this.cardList && this.cardList.length && this.search) {
  //       const filterPipe = new FilterPipe();
  //     const keys = ['name', 'email', 'phoneNumber', 'positionTitle'];
  //     const fiteredArr = filterPipe.transform(this.cardList, this.search, keys);
  //     if (fiteredArr) {
  //       this.cardList = fiteredArr;
  //     }
  //   } else {
  //     this.getCards();
  //   }
  // }

  onPageChange(page) {
    this.page = page;
    if (this.search && this.search.length) {
      this.getCards();
    } else {
      this.getCards();
    }
  }

  filter(ev) {
    this.groupId = ev.target.value;
    this.getCards();
  }

  coutAudioPlay(_card) {
    if (_card) {
      this.audioPlayer = new Audio(this.commonService.getFile(_card.audio));
      this.process = true;
      this.audioPlayer.play();
    } else {
      return;
    }
    if (!this.isLoggedIn) {
      this.cardsService.audioPlayCount({ id: _card._id });
    }
  }

  stopAudio(cardAudio) {
    this.audioPlayer.src = '';
    this.process = false;
    this.audioPlayer = new Audio(this.commonService.getFile(cardAudio));
    this.audioPlayer.pause();
  }
}
