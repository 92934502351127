import { library } from '@fortawesome/fontawesome-svg-core';

import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons/faPinterestP';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';


const icons = [
  faFacebookF, faTwitter, faLinkedinIn, faPinterestP, faEnvelope
];

library.add(...icons);
