import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class TransactionService {
  apiUrl: any = environment.api.user;

  constructor(
    private commonService: CommonService
  ) { }

  getTransactions() {
    return this.commonService.get(`${this.apiUrl}/transactions`, localStorage.getItem('token'), {});
  }

  addGroup(obj) {
    return this.commonService.create(`${this.apiUrl}`, localStorage.getItem('token'), obj);
  }

  update(obj) {
    return this.commonService.edit(this.apiUrl, localStorage.getItem('token'), obj);
  }

  delete(id) {
    return this.commonService.delete(`${this.apiUrl}`, localStorage.getItem('token'), id);
  }

}
