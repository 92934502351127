import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import Messages from './../../../i18n/messages';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/auth/login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  providers: [LoginService]
})
export class ForgetPasswordComponent implements OnInit {
  busy: Promise<any>;
  public model = {
    email: ''
  };
  type = '';
  constructor(
    private _router: Router,
    private _service: LoginService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this._router.url === '/magic-link-login') {
      this.type = 'magic-link';
    } else {
      this.type = 'forgot-password';
    }
  }

  submit() {
    if (this.type === 'magic-link') {
      this.magicLinkLogin();
    } else {
      this.sendEmail();
    }
  }

  sendEmail() {
    this.busy = this._service.forgetPassword(this.model.email, '').then(
      (res: any) => {
        this.toastr.success(res.message);
      },
      (error) => {
        if (error.headers._headers.get('content-type')[0] == "application/json; charset=utf-8") {
          this.toastr.error(error.json().message);
        } else {
          this.toastr.error(Messages.resetUnable);
        }
      }
    );
  }


  magicLinkLogin() {
    this._service.magicLinkLogin({ email: this.model.email }).then(res => {
      if (res) {
        this.toastr.success(res.message);
      }
    }, error => {
      this.toastr.error(error.json().message);
    });
  }

  getTitle() {
    if (this.type === 'magic-link') {
      return 'Magic Link Login';
    } else if (this.type === 'forgot-password') {
      return 'Forgot Password';
    }
  }
}
