import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from './../../shared/filter.pipe'
import { BroadcastService } from './../../services/broadcast.service';
import { ToastrService } from 'ngx-toastr';
import { userStatus } from './../../../i18n/constants';

@Component({
  selector: 'app-broadcast',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
  providers: [BroadcastService]
})
export class BroadcastComponent implements OnInit {
  busy: Promise<any>;
  //loader: Boolean = true;
  submitBtn: Boolean = false;
  cancelBtn: Boolean = false;
  
  public model = {
    message: ''
  }

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _service: BroadcastService,
  ) { }

  ngOnInit() { }

  sendBroadcast() {
    this.submitBtn = true;
    //this.cancelBtn = true;
    const user = {
      message: this.model.message
    };
    this.busy = this._service.sendBroadcast(user).then((res) => {
      this.submitBtn = false;
      //this.cancelBtn = false;
      this.model.message = '';
      return this.toastr.success(res.message);
    }, (error) => {
      this.submitBtn = false;
      //this.cancelBtn = false;
      let errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }
}
