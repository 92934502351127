import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  model = {
    name: '',
    email: '',
    phone: '',
    message: ''
  };
  errors = {};

  constructor(
    private commonService: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  sendMessage() {
    if (this.model) {
      this.commonService.contactUs(this.model).then(res => {
        if (res) {
          this.errors = '';
          this.toastr.success(res.message);
          // this.router.navigate(['/']);
        }
      }, error => {
        this.errors = error.json();
        // console.log(this.errors, '---------------asdflk')
        // this.toastr.error(error.json().message);
      });
    }
  }
}
