import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

import { environment } from './../../../environments/environment';
// declare let IN: any;
export class User {
  constructor(
    public email: string,
    public password: string,
    public role: string
  ) { }
}

@Injectable()
export class LoginService {
  apiUrl: string = environment.api.root;
  website: string = environment.api.website;

  constructor(
    private _router: Router,
    private http: Http,
  ) { }

  signin(user) {
    return this.http.post(`${this.apiUrl}user/login`, user)
      .toPromise().then(response => response.json());
  }

  tokenVerification(token) {
    return this.http.get(this.apiUrl + 'user/' + token)
      .toPromise().then(response => response.json());
  }

  forgetPassword(email, role) {
    const obj = { email: email, role: role };
    return this.http.put(this.apiUrl + 'user/forgot-password', obj)
      .toPromise().then(response => response.json());
  }

  resetPassword(token, obj) {
    const headers = new Headers({ 'authorization': `Bearer ${token}` });
    const options = new RequestOptions({ headers: headers });
    return this.http.put(this.apiUrl + 'user/password', obj, options)
      .toPromise().then(response => response.json());
  }

  linkedinData(code) {
    return this.http.get(this.apiUrl + 'user/linkedIn?code=' + code)
      .toPromise().then(response => response.json());
  }

  magicLinkLogin(obj) {
    return this.http.put(this.apiUrl + 'user/magic-link', obj)
      .toPromise().then(response => response.json());
  }

}
