import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {
  model = {
    number: ''
  };

  errors = {};

  constructor(
    private commonService: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  sendSms() {
    this.commonService.sendSms(this.model).then (res => {
      this.toastr.success(res.message);
    }, error => {
      this.errors = error.json();
      // this.toastr.error(error.json().message);
    });
  }
}
