import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, Route } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SignupService } from '../../services/auth/signup.service';
import { roles } from './../../../i18n/constants';
import Message from './../../../i18n/messages';
import { NgbModalConfig, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-signup',
  templateUrl: 'signup.component.html',
  styleUrls: ['signup.component.scss'],
  providers: [SignupService, NgbModalConfig, NgbModal]
})

export class SignupComponent implements OnInit {
  @ViewChild('myInput')
  busy: Promise<any>;
  country: String[];
  organizations: any = [];
  submitButton: Boolean = false;
  domainRequired: Boolean = false;
  roles: Object = roles;
  public model = {
    fname: '',
    lname: '',
    phone: '',
    email: '',
    code: '+1',
    password: '',
    confpassword: '',
    organization: '',
    role: 'user'
  };
  pageContent: any;

  options: NgbModalOptions = {
    size: 'lg'
  };

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpClient,
    private _service: SignupService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
   }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/dashboard']);
    }

    this.getOrganizations(); // To get all organizations

    // Country dial_code
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy("dial_code"));
        this.country = this.removeDuplicates(this.country);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
  }

  getOrganizations() {
    this.busy = this._service.getOrganizations().then((res: any) => {
      if (res.data.length > 0) {
        this.organizations = res.data;
        // console.log("ORG : ", this.organizations)
      }
    },
    error => {
      this.submitButton = false;
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  signup() {
    this.submitButton = true;
    if (this.model.password === this.model.confpassword) {
      let org = '';
      const userAddress = '';
      if (this.model.role === 'organization') {
        org = this.model.organization;
      }
      const user = {
        firstName: this.model.fname,
        lastName: this.model.lname,
        organizationId: org,
        phoneNumber: {
          code: this.model.code,
          number: Number(this.model.phone)
        },
        email: this.model.email,
        password: this.model.password,
        role: 'user'
      };

      this.busy = this._service.signup(user).then((res) => {
        if (res.statusCode === 200) {
          this.toastr.success(res.message);
          this.router.navigate(['/login']);
        } else if (res.message) {
          this.submitButton = false;
          return this.toastr.error(res.message);
        } else {
          this.submitButton = false;
          return this.toastr.error(Message.commonMessage);
        }
      },
        error => {
          console.log(error);
          this.submitButton = false;
          const errJson = JSON.parse(error._body);
          return this.toastr.error(errJson.message);
        });
    } else {
      this.submitButton = false;
      return this.toastr.error(Message.confirmPassword);
    }
  }

  setRequired() {
    if (this.model.role === 'user') {
      this.domainRequired = false;
    } else {
      this.domainRequired = true;
    }
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  removeDuplicates(country) {
    return country.reduce((accum, row) => {
      if (accum.findIndex(r => r['dial_code'] === row['dial_code']) === -1) {
        accum.push(row);
      }
      return accum;
    }, []);
  }

  openTermsCondition(termscondition) {
    this.commonService.getPage('terms-conditions').then(res => {
      this.pageContent = res.data['content'];
      this.modalService.open(termscondition, this.options);
     }, error => {
       this.toastr.error(error.message);
     });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  agree() {
    const checkbox = document.getElementById('tc');
    if (checkbox) {
      checkbox['checked'] = true;
    }
    this.modalService.dismissAll();
  }
}
