import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class BroadcastService {
  apiUrl: any = environment.api.card;

  constructor(
    private commonService: CommonService
  ) { }

  sendBroadcast(obj) {
    return this.commonService.create(`${this.apiUrl}/broadcast-info`, localStorage.getItem('token'), obj);
  }
}