import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentRestrictions } from '../../../../../src/objects/options/componentRestrictions';
import { Address } from '../../../../../src/objects/address';
import { AddressComponent } from '../../../../../src/objects/addressComponent';
import { CardsService } from '../../../services/card.service';
import { CommonService } from '../../../services/common.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Angular2SocialLoginModule } from 'angular2-social-login';
import { ImageCroppedEvent } from '../../../shared/utils/interfaces/image-cropped-event.interface';
import { AudioRecordingService } from '../../../services/audio-recording.service';
import { DomSanitizer } from '@angular/platform-browser';
import Messages from '../../../../i18n/messages';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-edit-card.component.html',
  providers: [NgbModalConfig, NgbModal]
})
export class AddCardComponent implements OnDestroy, OnInit {

  filePath = environment.api.fileUpload;
  addCardForm: FormGroup;
  cardSelected: any = null;
  colors: Array<object> = [];
  domainUrl: String = '';
  domainData: Array<object>;
  selectedFile: File;
  cardColor: String = '';
  lat: Number = 0; long: Number = 0;
  selectedLogo: any = '';
  cardDetails: any;
  intPhoneValue: any;

  /** files uploaded */
  audioFile: any = '';
  logoFile: any = '';
  userImage: any = '';
  country: String[];
  audioFilePath: any;
  fileType: String = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  imageData = {};
  process = false;
  audioProcess = false;
  logoProcess = false;
  spinner = false;

  audioPlayer = new Audio();
  recordedAudio = new FileReader();

  @ViewChild('openModal') openModal;
  @ViewChild('fileInput') fileInput;
  @ViewChild('logoInput') logoInput;
  @ViewChild('userImageInput') userImageInput;

  private subscription: Subscription;
  private modalRef;

  /** audio */
  isRecording = false;
  recordedTime;
  blobUrl;

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private commonService: CommonService,
    private cardsService: CardsService,
    private httpService: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
      this.toastr.error(Messages.recordingFailed);
    });

    this.audioRecordingService.getRecordedTime().subscribe((time) => {
      this.recordedTime = time;
    });

    this.audioRecordingService.getRecordedBlob().subscribe((data) => {
      this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data.blob));
      // this.audioFile = data.title;
      this.uploadImage(data, 'audio');
    });
  }

  ngOnDestroy() {
    localStorage.removeItem('domainLogo');
    localStorage.removeItem('cardNo');
    localStorage.removeItem('userImage');
    localStorage.removeItem('domainUrl');
    this.modalService.dismissAll();
    this.abortRecording();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const id = params.id;
      if (id) {
        this.createForm();
        this.cardsService.getCardById(id).then(res => {
          this.cardDetails = res.data;
          this.initCard(this.cardDetails);
        });
      } else {
        this.initCard(null);
      }
    });
  }


  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.audioRecordingService.startRecording();
    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.audioRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.audioRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
    this.audioProcess = false;
    this.deleteFile(this.audioFile);
    this.audioFile = this.cardDetails.audio;
  }

  initCard(card) {
    if (card != null) {
      this.updateForm(this.cardDetails);
    } else {
      const storage = this.commonService.storage;
      if (storage && storage.cardNo) {
        this.cardSelected = storage.cardNo;
        if (this.cardSelected === 1) {
          this.cardColor = '#308ee0';
        } else {
          this.cardColor = '#8862e0';
        }
        localStorage.setItem('cardNo', this.cardSelected);
      }
      if (storage && storage.email) {
        this.domainUrl = storage.email;
      }
      if (storage && storage.domainData) {
        this.domainData = storage.domainData;
        if (!this.logoFile) {
          setTimeout(() => {
            this.openModal.nativeElement.click();
          }, 100);
        }
      }
      this.setValues();
    }
  }

  setValues() {
    if (localStorage.getItem('domainLogo') || localStorage.getItem('cardNo')) {
      this.logoFile = localStorage.getItem('domainLogo');
      this.cardSelected = localStorage.getItem('cardNo');
      this.domainUrl = localStorage.getItem('domainUrl');
    } else if (localStorage.getItem('userImage')) {
      this.userImage = localStorage.getItem('userImage');
    } else {
      this.router.navigate(['/create-card']);
    }
    this.createForm();
  }

  public highlightRow(_logo) {
    this.selectedLogo = _logo.logo;
  }

  open(content) {
    this.modalService.open(content);
  }

  closeModal() {
    // this.domainData = [];
    this.modalService.dismissAll();
  }

  uploadLogo() {
    if (this.selectedLogo) {
      this.logoFile = this.selectedLogo;
    }
    localStorage.setItem('domainLogo', this.logoFile);
    // this.domainData = [];
    this.modalService.dismissAll();
  }

  /** Begin: Add card Details */

  createForm() {
    this.getCountryCode();
    this.getColors();
    this.addCardForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      email: [localStorage.getItem('email'), [Validators.email, Validators.required]],
      cardColor: [this.cardColor, Validators.required],
      organizationName: ['', Validators.required],
      positionTitle: [''],
      code: [null],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(12)]],
      address: [''],
      fbProfileLink: [''],
      linkdInProfileLink: [''],
      youTubeChannelLink: [''],
      twitterProfileLink: [''],
      instagramProfileLink: [''],
      awsLink: [''],
      websiteUrl: [''],
      templateType: this.cardSelected
      // type: [null, Validators.required]
    });
  }

  updateForm(_card) {
    this.cardSelected = _card.templateType;
    this.logoFile = _card.logo;
    this.userImage = _card.image;
    this.cardColor = _card.cardColor;
    this.audioFile = _card.audio;
    this.audioFilePath = this.commonService.getFile(this.audioFile);
    // this.lat = _card.location.coordinates.lat;
    // this.long = _card.location.coordinates.long;

    const cardName = _card.name.split(/\s+/);
    this.addCardForm.patchValue({
      firstName: cardName.slice(0, -1).join(''),
      lastName: cardName.pop(),
      email: _card.email ? _card.email : localStorage.getItem('email'),
      cardColor: _card.cardColor,
      organizationName: _card.organizationName,
      positionTitle: _card.positionTitle,
      code: _card.phoneNumber.code,
      phoneNumber: _card.phoneNumber.number,
      address: _card.address,
      location: _card.location,
      type: 1,
      templateType: _card.templateType,
      fbProfileLink: _card.fbProfileLink,
      linkdInProfileLink: _card.linkdInProfileLink,
      youTubeChannelLink: _card.youTubeChannelLink,
      twitterProfileLink: _card.twitterProfileLink,
      instagramProfileLink: _card.instagramProfileLink,
      awsLink: _card.awsLink,
      websiteUrl: _card.websiteUrl
    });
  }
  reset() {
    if (this.cardDetails && this.cardDetails._id) {
      this.updateForm(this.cardDetails);
    } else {
      this.createForm();
    }
  }

  get f() { return this.addCardForm.controls; }

  prepareCard() {
    const controls = this.addCardForm.controls;
    const cardInfo = {
      name: controls['firstName'].value + ' ' + controls['lastName'].value,
      email: controls['email'].value,
      phoneNumber: {
        code: controls['code'].value,
        number: controls['phoneNumber'].value
      },
      address: controls['address'].value,
      cardColor: controls['cardColor'].value,
      organizationName: controls['organizationName'].value,
      positionTitle: controls['positionTitle'].value,
      fbProfileLink: controls['fbProfileLink'].value,
      linkdInProfileLink: controls['linkdInProfileLink'].value,
      youTubeChannelLink: controls['youTubeChannelLink'].value,
      twitterProfileLink: controls['twitterProfileLink'].value,
      instagramProfileLink: controls['instagramProfileLink'].value,
      awsLink: controls['awsLink'].value,
      websiteUrl: controls['websiteUrl'].value,
      image: this.userImage,
      logo: this.logoFile,
      audio: this.audioFile,
      templateType: this.cardSelected,
      type: 1
    };
    if (this.lat && this.long) {
      return ({
        ...cardInfo,
        location: {
          coordinates: [this.lat, this.long],
          type: 'Point'
        }
      });
    }
    return cardInfo;
  }

  submit() {
    this.spinner = true;
    const controls = this.addCardForm.controls;
    if (this.addCardForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.spinner = false;
      return;
    } else {
      let card = this.prepareCard();
      if (card && this.cardDetails && this.cardDetails['_id']) {
        card = Object.assign({
          id: this.cardDetails._id
        }, card);
        this.editCard(card);
      } else {
        this.addCard(card);
      }
    }
  }

  addCard(_card) {
    this.cardsService.saveCard(_card).then(res => {
      if (res && res.statusCode === 200) {
        this.cardDetails = res.data;
        setTimeout(() => {
          this.toastr.success(res.message);
          this.router.navigateByUrl('/my-card?type=' + 1);
          this.spinner = false;
        }, 500);
      }
    }, error => {
        this.spinner = false;
        return this.toastr.error(error.json().message);
    });
  }

  editCard(_card) {
    this.cardsService.editCard(_card).then(res => {
      if (res && res.statusCode === 200) {
        this.cardDetails = res.data;
        setTimeout(() => {
          this.toastr.success(res.message);
          this.spinner = false;
          this.router.navigateByUrl('/my-card?type=' + 1);
        }, 500);
      }
    }, error => {
      this.spinner = false;
      return this.toastr.error(error.json().message);
    });
  }

  public handleAddressChange(address: Address) {
    this.addCardForm.patchValue({
      address: address.formatted_address
    });
    if (address) {
      if (address.geometry.location.lat()) {
        this.lat = address.geometry.location.lat();
      }
      if (address.geometry.location.lng()) {
        this.long = address.geometry.location.lng();
      }
    }
  }

  changeColor(ev) {
    this.cardColor = ev.target.value;
  }

  changeTemplate(ev) {
    this.cardSelected = ev.target.value;
  }

  // changeAudio(ev) {
  //   console.log(this.audioFilePath , 'ahdsklfhklahsdlf')
  //   this.ngOnInit();
  // }

  playAudio(ev, _audioFile) {
    const audio = new Audio();
    audio.src = '';
    if (_audioFile) {
      this.audioFile = _audioFile;
    } else if (ev) {
      this.audioFile = ev.target.value;
    } else { return; }
    if (this.audioFile) {
      this.audioFilePath = this.commonService.getFile(this.audioFile);
      audio.src = this.audioFilePath;
      audio.play();
    }
  }

  /** End: Add card Details */

  async uploadImage(ev, type) {
    this.selectedLogo = '';
    this.selectedFile = null;
    if (type === 'userImage' || type === 'logo') {
      const url = ev;

      /* convert base64 to file data  */

      await (await fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'abc.jpg');
          this.selectedFile = file;
        })
      );
    } else {
      this.process = true;
    if (ev && ev.blob) {
      const file = new File([ev.blob], 'xyz.mp3');
      this.selectedFile = file;
      } else {
        this.selectedFile = ev.target.files[0];
      }
    }
    if (this.selectedFile) {
      this.cardsService.uploadFile(this.selectedFile).then(res => { 
        if (type === 'audio') {
          this.audioFile = res.data.fileName;
          this.cdr.detectChanges();
          this.audioFilePath = this.commonService.getFile(this.audioFile);
          this.fileInput.nativeElement.value = '';
        } else if (type === 'userImage') {
          this.userImage = res.data.fileName;
          localStorage.setItem('userImage', this.userImage);
          this.userImageInput.nativeElement.value = '';
          this.modalService.dismissAll();
        } else {
          this.logoProcess = true;
          this.logoFile = res.data.fileName;
          this.logoInput.nativeElement.value = '';
          this.modalService.dismissAll();
        }
        setTimeout(() => {
          this.process = false;
          this.logoProcess = false;
          this.toastr.success(res.message);
        }, 500);
      }, error => {
        this.process = false;
        this.logoProcess = false;
        this.toastr.error(error.json().message);
      });
    }

  }

  deleteFile(_fileName) {
    if (_fileName) {
      this.cardsService.deleteFile(_fileName).then(res => {
        if (res) {
          this.toastr.success(res.data);
          this.ngOnInit();
          this.audioFile = '';
          this.audioFilePath = '';
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }
  }

  uploadUserImage(ev, cropper, type) {
    this.fileType = type;
    this.imageChangedEvent = event;
    this.modalService.open(cropper);
  }

  getLogoImage(_path) {
    if (_path) {
      // this.domainData = [];
      return this.commonService.getFile(_path);
    }
  }

  getAudioFile(ev) {
    if (ev && ev.target.value) {
      // this.domainData = [];
      this.stopAudio(ev.target.value);
      this.audioFile =  ev.target.value;
      this.cdr.detectChanges();
    }
  }
  getCountryCode() {
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.intPhoneValue = this.country[0]['dial_code'];
        this.addCardForm.patchValue({
          // phoneNumber: {
          code: this.intPhoneValue
          // }
        });
      }
    );
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  getFileName(fileName) {
    if (fileName) {
      return fileName.split("/").pop();
    }
  }

  getColors() {
    this.commonService.getColor().then(res => {
      this.colors = res.data;
    }, error => {
      console.log(error, '----------error in getting  color');
    });
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }

  getComponentTitle(): string {
    let result;
    if (!this.cardDetails || !this.cardDetails._id) {
      result = 'Add Card Details';
    } else if (this.cardDetails) {
      result = 'Edit Card Details';
    }
    return result;
  }

  audioPlay(cardAudio) {
    if (cardAudio) {
      this.audioPlayer = new Audio(this.commonService.getFile(cardAudio));
      this.audioProcess = true;
      this.audioPlayer.play();
    } else {
      return;
    }
  }

  stopAudio(cardAudio) {
    this.audioPlayer.src = '';
    this.audioProcess = false;
    this.audioPlayer = new Audio(this.commonService.getFile(cardAudio));
    this.audioPlayer.pause();
  }
}

