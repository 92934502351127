import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { CommonService } from './common.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/catch';

import { environment } from '../../environments/environment';

export class User {
  constructor(
    public email: String,
    public password: String) { }
}

@Injectable()
export class ProfileService {
  apiUrl: String = environment.api.user;
  imageUrl: String = environment.api.fileUpload;
  apiOrgUrl: String = environment.api.organization;
  rootUrl: String = environment.api.root;

  constructor(
    private _router: Router,
    private commonService: CommonService,
    private http: Http,
  ) { }

  userDetails() {
    return this.commonService.get(this.apiUrl, localStorage.getItem('token'), {});
  }

  update(obj) {
    return this.commonService.edit(this.apiUrl, localStorage.getItem('token'), obj);
  }

  upload(obj) {
    return this.commonService.upload(this.imageUrl, obj);
  }

  changePassword(obj) {
    return this.commonService.edit(`${this.apiUrl}/password`, localStorage.getItem('token'), obj);
  }

  changeEmail(obj) {
    return this.commonService.edit(`${this.apiUrl}/email`, localStorage.getItem('token'), obj);
  }

  getOrganizations() {
    return this.commonService.get(this.apiOrgUrl, localStorage.getItem('token'), {});
  }

  getUserOrganization() {
    return this.commonService.get(`${this.apiUrl}/common/status`, localStorage.getItem('token'), {});
  }

  changeOrganization(obj) {
    return this.commonService.edit(`${this.apiOrgUrl}/switch`, localStorage.getItem('token'), obj);
  }

  makeIndividual() {
    const user = { id: localStorage.getItem('userID'), status: 3}
    return this.commonService.edit(`${this.apiOrgUrl}/user-status`, localStorage.getItem('token'), user);
  }

  logout() {
    return this.commonService.logout(`${this.apiUrl}/logout`, localStorage.getItem('token'));
  }

  setThirdParty(data) {
    const url = this.rootUrl + 'notification/settings';
    return this.commonService.edit(url, localStorage.getItem('token'), data);
  }
}
