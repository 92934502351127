import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from './../../services/auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  busy: Promise<any>;
  public samplePagesCollapsed = true;
  public name: String;
  public userImage: String = '';
  public apiUrl = environment.api.user;
  public imageUrl = environment.api.fileUpload;
  public role = localStorage.getItem('role');

  constructor(
    private router: Router,
    private authService: AuthGuardService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.name = this.capitalize(localStorage.getItem('firstName')) + ' ' + this.capitalize(localStorage.getItem('lastName'));
    if (localStorage.getItem('profileImage')) {
      this.userImage = this.commonService.getFile(localStorage.getItem('profileImage'));
    }

    /** menu for cards-management section */
    /********** Please optimize it  ********/
    const dropdown = document.getElementsByClassName('cards-management');
    let i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function () {
        this.classList.toggle('active');
        const dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }
    if (this.role === 'user') {
      document.getElementById('user-menu').style.display = 'none';
    }

    /** menu for users-management section */
    const userDropdown = document.getElementsByClassName('users-management');
    let u;
    for (u = 0; u < userDropdown.length; u++) {
      userDropdown[u].addEventListener('click', function () {
        this.classList.toggle('active');
        const dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }

    /** menu for group-management section */
    // const groupDropdown = document.getElementsByClassName('groups-management');
    // let g;
    // for (g = 0; g < groupDropdown.length; g++) {
    //   groupDropdown[g].addEventListener('click', function () {
    //     this.classList.toggle('active');
    //     const dropdownContent = this.nextElementSibling;
    //     if (dropdownContent.style.display === 'block') {
    //       dropdownContent.style.display = 'none';
    //     } else {
    //       dropdownContent.style.display = 'block';
    //     }
    //   });
    // }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') { return ''; }
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  checkAdminRole() {
    if (this.role === 'organization') {
      return true;
    } else {
      return false;
    }
  }
  logout() {
    this.commonService.logout(`${this.apiUrl}/logout`, localStorage.getItem('token')).then((resLogout) => {
      localStorage.removeItem('userID');
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      localStorage.removeItem('email');
      localStorage.removeItem('firstName');
      localStorage.removeItem('lastName');
      localStorage.removeItem('profileImage');

      this.authService.logout();
      this.commonService.notifyOther({ option: 'logged-out' });
      this.spinner.show();
      setTimeout(() => {
        this.router.navigate(['/']);
        this.spinner.hide();
      }, 3000);
    },
    error => {
      console.log("Sidebar Logout error....");
      //return this.toastr.error(Messages.updateEmailLogoutError);
    });
  }

}
