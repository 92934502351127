import { Component, OnInit } from '@angular/core';
import { AdService } from './../../shared/ad/ad.service';
import { AdItem } from './../../shared/ad/ad-item';
import { HeroProfileComponent } from './../../shared/ad/hero-profile.component';
import { CommonService } from './../../services/common.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  ads: AdItem[];
  subscribedUser: Boolean = false;
  website: String = environment.api.website;
  private subscription: Subscription;
  copyright:number = new Date().getFullYear();

  constructor(
    private _service: AdService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    if (localStorage.getItem('isSubscription') !== 'true') {
      this._service.getAds().then((res: any) => {
        const data = res.data;
        if (data && data.length) {
          this.ads = data.map(add => {
            return new AdItem(HeroProfileComponent,
              {
                name: add.name,
                bio: add.description,
                img: this.getFile(add.image),
                link: this.getLink(add.link)
              });
          });
          this.subscribedUser = true;
        }
      });
    } else {
      this.subscribedUser = false;
    }

    this.subscription = this.commonService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty('option') && res.option === 'isSubscription') {
        localStorage.setItem('isSubscription', res.value);
        if (res && res.value) {
          this.subscribedUser = true;
        } else {
          this.subscribedUser = false;
        }
        this.ngOnInit();
      }
    });
  }

  getFile(_path) {
    if (_path) {
      return this.commonService.getFile(_path);
    }
  }

  getLink(_link) {
    if (_link && _link.includes('http', 'https')) {
      return _link;
    } else {
      return `https://${_link}`;
    }
  }
}
