import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'angular2-social-login';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../services/auth/login.service';
import { SignupService } from '../../services/auth/signup.service';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { roles } from './../../../i18n/constants';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService]
})

export class LoginComponent implements OnInit {
  busy: Promise<any>;
  roles: Object = roles;
  public model = {
    email: '',
    password: ''
  };
  public user;
  sub: any;
  loading = false;
  submitButton: Boolean = false;
  name: any;
  public linkedIn: Object = environment.linkedIn;

  constructor(
    public _auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _service: LoginService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private signupService: SignupService
  ) { }



  ngOnInit() {
    if (!!localStorage.getItem('token')) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/login']);
    }

    const code: string = this.activatedRoute.snapshot.queryParamMap.get('code');
    if (code) {
      this.spinner.show();
      this.getProfileData(code);
    }
  }

  getProfileData(code) {
    this.busy = this._service.linkedinData(code).then((res: any) => {
      const data = res.data;
      const encryptedString = this.commonService.setToken('encrypt', data.loginToken.toString());
      localStorage.setItem('userID', data.userId);
      localStorage.setItem('role', data.role);
      localStorage.setItem('token', encryptedString);
      localStorage.setItem('email', data.email);
      localStorage.setItem('firstName', data.firstName);
      localStorage.setItem('lastName', data.lastName);
      localStorage.setItem('profileImage', data.profileImage);
      this.showSpinner();
    }, error => {
      const errJson = JSON.parse(error._body);
      this.spinner.hide();
      return this.toastr.error(errJson.message);
    });
  }

  signin() {
    this.submitButton = true;
    this.busy = this._service.signin(this.model).then((res: any) => {
      const data = res.data;
      const encryptedString = this.commonService.setToken('encrypt', data.loginToken.toString());
      localStorage.setItem('userID', data.userId);
      localStorage.setItem('role', data.role);
      localStorage.setItem('token', encryptedString);
      localStorage.setItem('email', data.email);
      localStorage.setItem('firstName', data.firstName);
      localStorage.setItem('lastName', data.lastName);
      localStorage.setItem('profileImage', data.profileImage);
      this.showSpinner();
    }, error => {
      this.submitButton = false;
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  socialSignIn(media) {
    if (media === 'linkedin') {
      ////// LinkedIn login auth ///////
      this.spinner.show();
      const scope = ['r_liteprofile', 'r_emailaddress'];
      // tslint:disable-next-line:max-line-length
      document.location.href = `${this.linkedIn['authUrl']}?response_type=${this.linkedIn['response_type']}&scope=${scope}&client_id=${this.linkedIn['client_id']}&state=in&redirect_uri=${this.linkedIn['redirect_uri']}`;
    } else {
      this.sub = this._auth.login(media).subscribe(fbResponse => {
        if (fbResponse) {
          const data = fbResponse;
          const userData = {
            social: {
              fbId: data['uid']
            },
            profileImage: data['image'],
            firstName: data['name'].split(' ').slice(0, -1).join(' '),
            lastName: data['name'].split(' ').slice(-1).join(' '),
            email: data['email'],
            role: 'user'
          };
          ////// Facebook login ///////
          this.socialLogin(userData);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }
  }

  showSpinner() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.router.navigateByUrl('/dashboard');
    }, 3000);
  }

  /******* Facebook and LinkeDIn social login user info *********/
  socialLogin(userData) {
    this.signupService.signupSocial(userData).then((res: any) => {
      const encryptedString = this.commonService.setToken('encrypt', res['data']['loginToken'].toString());
      localStorage.setItem('userID', res['data']['_id']);
      localStorage.setItem('role', res['data']['role']);
      localStorage.setItem('token', encryptedString);
      localStorage.setItem('email', res['data']['email']);
      localStorage.setItem('firstName', res['data']['firstName']);
      localStorage.setItem('profileImage', res['data']['profileImage']);
      localStorage.setItem('lastName', res['data']['lastName']);
      this.showSpinner();
    }, error => {
      this.toastr.error(error.message);
    });
  }

}