import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any, input: string, searchableList: any) {
    if (input) {
      input = input.toLowerCase();
      const searchInput = input.split(' ');
      return value.filter(function (el: any) {
        let isTrue = false;
        for (const k in searchableList) {
          if (searchInput.length > 1) {
              if(el['firstName'].toLowerCase() === searchInput[0].toLowerCase() && el['lastName'].toLowerCase() === searchInput[1].toLowerCase()){
                isTrue = true;
              }          
          } else if (searchInput.length >= 1 && el['title']) {
            if ( el['title'].toLowerCase().includes(input.toLowerCase())) {
              isTrue = true;
            }
          } else if (el[searchableList[k]] && el[searchableList[k]].toString().toLowerCase().indexOf(input) > -1) {
            isTrue = true;
          }
          if (isTrue) {
            return el
          }
        }
      })
    }
    return value;
  }
}
