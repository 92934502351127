import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angular2SocialLoginModule } from 'angular2-social-login';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { HomeRoutingModule } from './home.routing';
import { ToastrModule } from 'ngx-toastr';
import { NgxStripeModule } from 'ngx-stripe';
import { ChartsModule } from 'ng2-charts';


/** Services */
import { AuthGuard } from '../services/auth/auth.guard';
import { AuthGuardService } from '../services/auth/auth.service';
import { UsersService } from '../services/user.service';
import { CommonService } from '../services/common.service';
import { CardsService } from '../services/card.service';
import { GroupService } from '../services/group.service';
import { PromotionService } from '../services/promotion.service';
import { DashboardService } from '../services/dashboard.service';
import { TransactionService } from '../services/transaction.service';
import { BroadcastService } from '../services/broadcast.service';
import { AdDirective } from './../shared/ad/ad.directive';
import { AdService } from './../shared/ad/ad.service';

/** Components */
import { NavbarComponent } from '../components/navbar/navbar.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HomeComponent } from './home.component';
import { AdBannerComponent } from './../shared/ad/ad-banner.component';
import { HeroJobAdComponent } from './../shared/ad/hero-job-ad.component';
import { HeroProfileComponent } from './../shared/ad/hero-profile.component';
import { LayoutComponent } from '../Layout-main/layout/layout.component';
import { DownloadAppComponent } from '../Layout-main/download-app/download-app.component';
import { ContactUsComponent } from '../Layout-main/contact-us/contact-us.component';
import { SubscriptionPlanComponent } from '../Layout-main/subscription-plan/subscription-plan.component';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    LayoutComponent,
    HomeComponent,
    AdBannerComponent,
    HeroJobAdComponent,
    HeroProfileComponent,
    DownloadAppComponent,
    ContactUsComponent,
    SubscriptionPlanComponent,
    AdDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    Angular2SocialLoginModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    NgxStripeModule.forRoot('pk_test_qUM3nuNKcfmpN6uOAJtaTggR00qQu5h3w5'),
    NgxSpinnerModule,
    ChartsModule
  ],

  providers: [
    AuthGuardService, DashboardService, AuthGuard, CommonService, UsersService, CardsService, GroupService, PromotionService, AdService
  ],
  bootstrap: [HomeComponent]
})

export class HomeModule { }

