/* -----------------------------------------------------------------------
   * @ description : Main module to include all the constants used in project.
----------------------------------------------------------------------- */
export const colors = ["#70B139", "#34B7E3", "#FBBD06", "#9D1EA1", "#5DA22E", "#2AA8E2", "#F7B104", "#8C1895", "#70B139", "#34B7E3", "#FBBD06", "#9D1EA1", "#5DA22E", "#2AA8E2", "#F7B104", "#8C1895", "#70B139", "#34B7E3", "#FBBD06", "#9D1EA1", "#5DA22E", "#2AA8E2", "#F7B104", "#8C1895"];
export const dateFormat = { YYYYMMDD: 'YYYY-MM-DD' };
export const iPhoneDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
export const androidDevices = ['Android', 'android', 'Linux aarch64', 'Linux armv5tej1', 'Linux armv61', 'Linux armv71', 'Linux armv81', 'Linux i686', 'Linux i686 on x86_64', 'Linux i686 X11', 'Linux MSM8960_v3.2.1.1_N_R069_Rev', 'Linux ppc64', 'Linux x86_64 X11', 'Linux x86_64'];
export const roles = [{ name: 'Organization', role: 'organization' }, { name: 'Individual', role: 'user' }];
export const userStatus = [
  { id: 1, name: 'Request to Join', shortName: 'Request to Join' },
  { id: 2, name: 'Active User', shortName: 'Active' },
  { id: 3, name: 'Inactive User', shortName: 'Inactive' },
  { id: 4, name: 'Leave Request', shortName: 'Leave Request' },
  { id: 5, name: 'All Users', shortName: 'All Users' }
];
