import { Component, OnInit, ViewChild } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive';
import { Address } from './../../../../src/objects/address';
import { ProfileService } from './../../services/profile.service';
import { CommonService } from './../../services/common.service';
import Messages from './../../../i18n/messages';
import { environment } from '../../../environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [ProfileService, NgbModalConfig, NgbModal]
})
export class ProfileComponent implements OnInit {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  busy: Promise<any>;
  users: any;
  country: String[];
  selectedFile: File;
  alert: Boolean = false;
  loader: Boolean = true;
  submitBtn: Boolean = true;
  cancelBtn: Boolean = false;
  updateBtn: Boolean = false;
  showName: Boolean = true;
  formName: Boolean = false;
  editNameIcon: Boolean = true;
  showDomain: Boolean = true;
  formDomain: Boolean = false;
  editDomainIcon: Boolean = true;
  showPhone: Boolean = true;
  formPhone: Boolean = false;
  editPhoneIcon: Boolean = true;
  showAddress: Boolean = true;
  formAddress: Boolean = false;
  editAddressIcon: Boolean = true;
  public imageUrl = environment.api.fileUpload;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper = false;
  updating: Boolean = true;
  thirdPartyNotifications = false;

  public model = {
    fname: '',
    lname: '',
    email: '',
    code: '+1',
    phone: '',
    image: '',
    domain: '',
    address: '',
    role: 'organization'
  };

  constructor(
    private commonService: CommonService,
    config: NgbModalConfig,
    private fb: FormBuilder,
    private http: Http,
    private httpService: HttpClient,
    private _service: ProfileService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    // Country dial_code
    this.httpService.get('./assets/json/country.json').subscribe(
      data => {
        this.country = data as string[];
        this.country = (this.country).sort(this.jsonSortBy('dial_code'));
        this.country = this.removeDuplicates(this.country);
      },
      (err: HttpErrorResponse) => {
        console.log(err.message);
      }
    );
    if (localStorage.getItem('thirdPartyNotifications') === 'true') {
      this.thirdPartyNotifications = true;
    }
    this.userDetails(); // To get user's details
  }

  userDetails(reload = false) {
    this.busy = this._service.userDetails().then(
      (res: any) => {
        if (res.data) {
          let imagePath = '';
          let countryCode = '';
          if (res.data.profileImage) {
            localStorage.setItem('profileImage', res.data.profileImage);
            imagePath = this.commonService.getFile(res.data.profileImage);
          }
          if (res.data.phoneNumber.code && res.data.phoneNumber.code != '+') {
            // console.log("AAAAAAAAAAAAAAAAAAAAAa");
            countryCode = res.data.phoneNumber.code;
          } else {
            // console.log("SSSSSSSSSSSSSSSSSSS");
            countryCode = '+1';
          }
          // console.log(countryCode);

          localStorage.setItem('firstName', res.data.firstName);
          localStorage.setItem('lastName', res.data.lastName);

          this.model = {
            address: res.data.address,
            domain: res.data.companyEmailDomain,
            fname: res.data.firstName,
            lname: res.data.lastName,
            email: res.data.email,
            image: imagePath,
            code: countryCode,
            phone: res.data.phoneNumber.number,
            role: res.data.role
          };
          this.users = res.data;
          this.showHide();
          this.loader = false;

          if (reload) {
            window.location.reload();
          }

        }
      }, (error) => {
        if (error.status && error.statusText) {
          this.toastr.error(error.statusText);
        } else {
          console.log(Messages.somethingWrong);
        }
      }
    );
    //window.location.reload();
  }

  updateProfile() {
    this.submitBtn = false;
    this.cancelBtn = false;
    this.updateBtn = true;
    this.alert = true;

    let userImage = '';
    if (this.model.image) {
      const splitImage = (this.model.image).split(this.imageUrl + '/');
      if (splitImage[1] && splitImage[1] !== undefined) {
        userImage = splitImage[1];
      } else {
        userImage = splitImage[0];
      }
    }

    const user = {
      firstName: this.model.fname,
      lastName: this.model.lname,
      companyEmailDomain: this.model.domain,
      phoneNumber: {
        code: this.model.code,
        number: this.model.phone
      },
      address: this.model.address,
      profileImage: userImage
    };

    this.busy = this._service.update(user).then(
      (res: any) => {
        this.toastr.success(res.message);

        if (
          this.model.fname !== this.users.firstName ||
          this.model.lname !== this.users.lastName ||
          userImage !== this.users.profileImage
        ) {
          const milliseconds = 50000;
          const start = new Date().getTime();
          for (let i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
              break;
            }
          }
          this.userDetails(true);
        } else {
          this.userDetails();
        }
      },
      (error) => {
        if (error.status && error.statusText) {
          this.toastr.error(error.statusText);
        } else {
          this.toastr.error(Messages.somethingWrong);
        }
        this.showHide();
      }
    );
  }

  editField(field) {
    if (field === 'name') {
      this.showName = false;
      this.editNameIcon = false;
      this.formName = true;
    } else if (field === 'domain') {
      this.showDomain = false;
      this.editDomainIcon = false;
      this.formDomain = true;
    } else if (field === 'phone') {
      this.showPhone = false;
      this.editPhoneIcon = false;
      this.formPhone = true;
    } else if (field === 'address') {
      this.showAddress = false;
      this.editAddressIcon = false;
      this.formAddress = true;
    }
  }

  showHide(field = null) {
    if (field === 'name') {
      this.model.fname = this.users.firstName;
      this.model.lname = this.users.lastName;
    } else if (field === 'domain') {
      this.model.domain = this.users.companyEmailDomain;
    } else if (field === 'phone') {
      this.model.code = this.users.phoneNumber.code;
      this.model.phone = this.users.phoneNumber.number;
    } else if (field === 'address') {
      this.model.address = this.users.address;
    }

    this.formName = false;
    this.formDomain = false;
    this.formPhone = false;
    this.formAddress = false;

    this.showName = true;
    this.editNameIcon = true;
    this.showDomain = true;
    this.editDomainIcon = true;
    this.showPhone = true;
    this.editPhoneIcon = true;
    this.showAddress = true;
    this.editAddressIcon = true;

    this.alert = false;
    this.submitBtn = true;
    this.cancelBtn = false;
    this.updateBtn = false;
  }

  jsonSortBy(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  removeDuplicates(country) {
    return country.reduce((accum, row) => {
      if (accum.findIndex(r => r['dial_code'] === row['dial_code']) === -1) {
        accum.push(row);
      }
      return accum;
    }, []);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  uploadProfileImage(ev, cropper) {
    this.updating = false;
    this.imageChangedEvent = event;
    this.modalService.open(cropper);
  }

  async uploadImage(event) {
    this.updating = true;
    if (event && event.target && event.target.files) {
      this.selectedFile = event.target.files[0];
    } else {
      const url = event;

      /** convert base64 to file data  */
      await (await fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'abc.jpg');
          this.selectedFile = file;
        })
      );
    }
    if (this.selectedFile) {
      this.busy = this._service.upload(this.selectedFile).then(
        (res: any) => {
          this.updating = false;
          if (res.statusCode === 200) {
            this.model.image = res.data.fileName;
            this.updateProfile();
          } else {
            this.toastr.error(Messages.somethingWrong);
            this.showHide();
          }
        },
        (error) => {
          if (error.status && error.statusText) {
            this.toastr.error(error.statusText);
          } else {
            this.toastr.error(Messages.somethingWrong);
          }
          this.showHide();
        }
      );
    } else {
      this.toastr.error(Messages.somethingWrong);
      this.showHide();
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }

  handleAddressChange(address: Address) {
    this.model.address = address.formatted_address;

    //console.log(address.formatted_address);

    // this.addCardForm.patchValue({
    //   address: address.formatted_address
    // });
    // if (address) {
    //   if (address.geometry.location.lat()) {
    //     this.lat = address.geometry.location.lat();
    //   }
    //   if (address.geometry.location.lng()) {
    //     this.long = address.geometry.location.lng();
    //   }
    // }
  }

  setThirdParty(event) {
    console.log('event', event);
    const obj = {
      key: 'thirdPartyNotifications',
      status: !this.thirdPartyNotifications
    };
    this.thirdPartyNotifications = !this.thirdPartyNotifications;
    this._service.setThirdParty(obj).then(() => {
      const message = this.thirdPartyNotifications ? 'Enabled' : 'Disabled';
      this.toastr.success(message);
    });
  }

  // swaggers
  //https://medium.com/@coryminglee/uploading-file-by-post-method-in-angular6-62d7e9705e94
  //https://www.academind.com/learn/angular/snippets/angular-image-upload-made-easy/
}
