import { Component, OnInit } from '@angular/core';
import { Route, Router, CanLoad, NavigationExtras, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { FilterPipe } from '../../shared/filter.pipe';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    aboutUs: String = '';
    video: SafeHtml = '';
    originalAboutUs: any;
    showmoredesc: any;
    showlessdesc: any;
    pageType = 'video';
    pageContent: String = '';
    search = '';
    faqList: Array<object> = [];
    faqExists: Boolean = false;

    tabSelected: String = '';
    constructor(
        private commonService: CommonService,
        private router: Router,
        private sanitizer: DomSanitizer
    ) {
        this.showmoredesc = true;
        this.showlessdesc = false;

    }

    ngOnInit() {
        if (this.router.url.includes('terms-and-conditions')) {
           this.staticPage('terms-conditions');
        }
        if (this.router.url.includes('contact')) {
           this.staticPage('contact');
        }
        const sessionId = localStorage.getItem('token');
        if (!!sessionId) {
            this.router.navigate(['/dashboard']);
        } else {
            this.router.navigate(['/']);
        }
        this.getVideo();
    }
    /********** Get video for Home page *********/
    getVideo() {
        this.commonService.getPage('video').then(res => {
            this.video = this.sanitizer.bypassSecurityTrustHtml(res.data['content']);
        });
    }
    /********** Get video about us page *********/
    getData() {
        this.commonService.getPage('about-us').then(res => {
            this.originalAboutUs = res.data['content'];
            this.aboutUs = this.originalAboutUs;
            this.showLessDesc();
        });
    }



    showFullDesc() {
        this.pageContent = this.originalAboutUs;
        this.showmoredesc = false;
        this.showlessdesc = true;
        this.pageType = 'about-us';
        // this.tabSelected = 'page';
    }

    showLessDesc() {
        if (this.aboutUs.length > 1000) {
            this.aboutUs = this.aboutUs.slice(0, 1000) + '...';
            this.pageContent = this.aboutUs;
            this.showmoredesc = true;
            this.showlessdesc = false;
            // this.tabSelected = '';
        } else {
            return;
        }
    }

    changeTab(tab) {
        if (tab === '') {
            this.tabSelected = '';
            this.pageType = 'video';
            this.getVideo();
        } else if (tab === 'about-us') {
            this.tabSelected = tab;
            this.pageType = 'about-us';
            this.getData();
        } else {
            this.tabSelected = tab;
        }
    }

    staticPage(page) {
      if (page === 'contact') {
        this.faqExists = true;
        this.getPage('faq');
    } else {
        this.faqExists = false;
    }
        this.showlessdesc = true;
        this.showmoredesc = false;
        this.pageContent = '';
        this.pageType = page ? page : '';
        this.tabSelected = page === 'about-us' ? 'about-us' : 'page';
        // if (page === 'faq') {
        //     this.faqExists = true;
        // } else {
        //     this.faqExists = false;
        // }

        this.getPage(this.pageType);

    }

    getPage(type) {
        // |
        let _type = type;
        if (_type === 'faq' || _type === 'contact') {
          _type = 'faq';
          this.faqExists = true;
        } else {
          this.faqExists = false;
        }
        console.log('log',_type)
        this.commonService.getPage(_type).then(res => {
          console.log(res);
            if (this.faqExists) {
                this.faqList = res['data']['faqContent'];
            } else if (res && res.data) {
                this.pageContent = res.data['content'];
            }
        }, error => {
            console.log(error.message);
        });
    }

    getTitle() {
        if (this.pageType === 'video') {
            return 'Video';
        } else if (this.pageType === 'about-us') {
            return 'About Us';
        } else if (this.pageType === 'privacy-policy') {
            return 'Privacy Policy';
        } else if (this.pageType === 'terms-conditions') {
            return 'Terms & Conditions';
        } else if (this.pageType === 'investor-relations') {
            return 'Investor Relations';
        } else {
            return 'Frequently Asked Questions';
        }
    }

    searchFaq() {
        if (this.search) {
            const filterPipe = new FilterPipe();
            const filteredArr = filterPipe.transform(this.faqList, this.search, ['title']);
            this.faqList = filteredArr;
        } else {
            this.searchReset();
        }
    }

    searchReset() {
        this.search = '';
        this.getPage('faq');
    }
}
