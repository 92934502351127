/* -----------------------------------------------------------------------
   * @ description : Main module to include all the messages used in project.
----------------------------------------------------------------------- */

export default {
  /******* Common Messages ******/
  commonMessage: 'Something went wrong please try again later.',
  somethingWrong: 'Something went wrong please try again.',
  validCard: 'Please enter a valid card number!',
  passwordUnmatch: 'Password and confirmation password doesn\'t match.',
  passwordUnable: 'Unable change your password. Please try later.',
  resetUnable: 'Unable reset your password. Please try later.',
  unableActive: 'Unable to activate account please try again later',
  accountActivated: 'Congratulations, your account has been activated! You can now login.',
  confirmPassword: 'Password and confirmation password doesnt match.',
  updateEmailNote: `If you change the email address,
  you will get an email at your new email address. The email will asks you to verify the change by clicking the provided link. 
  It will require to click on the link that has just been sent to your email account to verify your email and continue the process.`,
  updateEmailLogoutError: 'Something went wrong please logout and verify your email address from your new email address.',
  updateOrganizationNote: `if you change the organization, new selected organization will appear in your account after confirmation of your 
  request by the organization admin and Until then, no organization is visible in your account.`,
  confirmEmail: 'Email and confirmation email doesnt match.',
  recordingFailed: `Can't record ! Please check recording media if connected properly`
};
