import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {

  plansList = [];

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.commonService.getSubscriptionPlans().then(res => {
      if (res && res['data']) {
        this.plansList = res['data'];
      }
    }, error => {
      console.log(error, '---------- error in getting subscription plans');
    });
  }

}
