import { Component, OnInit, Input, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterPipe } from './../../shared/filter.pipe'
import { PromotionService } from './../../services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { userStatus } from './../../../i18n/constants';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  providers: [PromotionService]
})
export class PromotionComponent implements OnInit {
  busy: Promise<any>;
  status = 2;
  search: String;
  data: any;
  users: any;
  page = 1;
  totalItems = 0;
  itemsPerPage = 10;
  loader: Boolean = true;
  column: Array<any> = [
    { id: 'name', name: 'Promotion Name', sorting: true, type: 'string', display: 'left', order: 'neutral' },
    { id: 'code', name: 'Code', sorting: false, type: 'code', display: 'left' },
    { id: 'disPercentage', name: 'Discount', sorting: false, type: 'percent', display: 'left' },
    { id: 'discription', name: 'Description', sorting: false, type: 'string', display: 'left' },
    { id: 'appliedDate', name: 'Applied On', sorting: false, type: 'datetime', display: 'left' }
  ];
  public model = {
    requestStatus: '2'  // Display all promotions
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private _service: PromotionService,
  ) { }

  ngOnInit() {
    this.getPromotions(); // To get user's promotion details
  }

  getPromotions(type = '2', reload = false) {
    this.busy = this._service.getPromotions(type).then((res: any) => {
      if (res.data && (res.data.length) > 0) {
        this.data = res.data;
      } else {
        this.data = [];
      }
      this.totalItems = this.data.length;
      this.loader = false;
      this.sliceList(this.page, this.data);
    }, (error) => {
      const errJson = JSON.parse(error._body);
      return this.toastr.error(errJson.message);
    });
  }

  searchUser() {
    if (this.search && this.search.trim().length) {
      this.users = this.data.filter(x => x.name.includes(this.search));
      this.totalItems = this.users.length;
      this.page = 1;
      this.sliceList(this.page, this.users);
    } else {
      this.totalItems = this.data.length;
      this.page = 1;
      this.sliceList(this.page, this.data);
    }
  }

  searchReset() {
    this.search = '';
    this.searchUser();
  }

  sliceList(page, data) {
    const start = (page - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    this.users = data.slice(start, end);
  }

  sortData(field, order) {
    this.column = this.column.map(x => field === x['id'] ? { ...x, 'order': order } : x);
    if (order == 'asc') {
      this.users = this.users.sort((a, b) => a[field].localeCompare(b[field]))
    } else {
      this.users = this.users.sort((a, b) => b[field].localeCompare(a[field]))
    }
  }

  onPageChange(page) {
    if (this.search && this.search.length) {
      this.sliceList(page, this.users);
    } else {
      this.sliceList(page, this.data);
    }
  }

  showData() {
    this.getPromotions(this.model.requestStatus);
  }
}
